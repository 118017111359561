<template>
  <b-sidebar id="filters-sidebar" :visible="isFiltersSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow
    backdrop no-header right @change="showFilterSidebar">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        
        <h5 class="mb-0">
            Global Filters
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">


          <!-- BPU -->
          <validation-provider #default="validationContext" name="BPU">
            <b-form-group label="BPU" label-for="bpu" :state="getValidationState(validationContext)">
              <v-select v-model="newFiltersData.bpu" :options="bpuOptions" :reduce="val => [val.value]" input-id="bpu" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Brand Category -->
          <validation-provider #default="validationContext" name="Brand Category">
            <b-form-group label="Brand Category" label-for="brand_category"
              :state="getValidationState(validationContext)">
              <v-select v-model="newFiltersData.brand_category" :options="brandCategoryOptions"
                :reduce="val => [val.value]" input-id="brand_category" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- APPLICATION FORM -->
          <validation-provider #default="validationContext" name="Application Form">
            <b-form-group label="Application Form" label-for="application_form"
              :state="getValidationState(validationContext)">
              <v-select v-model="newFiltersData.application_form" :options="applicationFormOptions"
                :reduce="val => [val.value]" input-id="application_form" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- CANAL -->
          <validation-provider #default="validationContext" name="Canal">
            <b-form-group label="Canal" label-for="canal" :state="getValidationState(validationContext)">
              <v-select v-model="newFiltersData.canal" :options="canalOptions"
                :reduce="val => [val.value]" input-id="canal" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!--Clasificacion -->

          <validation-provider #default="validationContext" name="Type">
            <b-form-group label="Type" label-for="clasificacion" :state="getValidationState(validationContext)">
              <v-select v-model="newFiltersData.clasificacion" :options="clasificacionOptions"
                :reduce="val => [val.value]" input-id="clasificacion" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Year -->
          <validation-provider #default="validationContext" name="Year">
            <b-form-group label="Year" label-for="year" :state="getValidationState(validationContext)">
              <v-select v-model="newFiltersData.year" :options="yearOptions" :reduce="val => [val.value]"
                input-id="year" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Month -->
          <validation-provider #default="validationContext" name="Month">
            <b-form-group label="Month" label-for="month" :state="getValidationState(validationContext)">
              <v-select v-model="newFiltersData.month" :options="monthOptions" :reduce="val => [val.value]"
                input-id="month" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>



          <!-- MONEDA-->
          <!-- <validation-provider
            #default="validationContext"
            name="Moneda"
            rules="required"
          >
            <b-form-group
              label="Moneda"
              label-for="moenda"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newFiltersData.moenda"
                :options="monedaOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="moenda"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Apply
            </b-button>

            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mr-2"
            >
              Cancel
            </b-button> -->

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
              Reset Filters
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
  BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, getCurrentInstance,watch } from 'vue'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import gql from 'graphql-tag'
//import { watch } from 'fs'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isFiltersSidebarActive',
    event: 'update:is-filters-sidebar-active',
  },
  props: {
    isFiltersSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    //const isUpdated = false;
    const bpuOptions = ref([])
    const brandCategoryOptions = ref([])
    const applicationFormOptions = ref([])
    const clasificacionOptions = ref([])
    const canalOptions = ref([])
    const yearOptions = ref([])
    const monthOptions = ref([])
    const filtersDataHelper = ref(null)


    const vm = getCurrentInstance().proxy

    const monedaOptions = [
      { label: 'Soles', value: 'Soles' },
      { label: 'Euros', value: 'Euros' },
    ]

    const blankFiltersData = {
      bpu: [],
      brand_category: [],
      application_form: [],
      canal: [],
      clasificacion: [],
      year: [],
      month: []
    };



    const newFiltersData = ref(JSON.parse(JSON.stringify(blankFiltersData)))
    const resetuserData = () => {
      newFiltersData.value = JSON.parse(JSON.stringify(blankFiltersData))
    }

    const onSubmit = () => {
      if (newFiltersData.value.bpu == null) newFiltersData.value.bpu = []
      if (newFiltersData.value.brand_category == null) newFiltersData.value.brand_category = []
      if (newFiltersData.value.application_form == null) newFiltersData.value.application_form = []
      if (newFiltersData.value.canal == null) newFiltersData.value.canal = []
      if (newFiltersData.value.clasificacion == null) newFiltersData.value.clasificacion = []
      if (newFiltersData.value.year == null) newFiltersData.value.year = []
      if (newFiltersData.value.month == null) newFiltersData.value.month = []

      filtersDataHelper.value = Object.assign({}, newFiltersData.value)
    

    }



    const showFilterSidebar = (val) => {
      if (val && filtersDataHelper.value != null) {
        newFiltersData.value = Object.assign({}, filtersDataHelper.value)
      }
      emit('update:is-filters-sidebar-active', val)
    }


    watch(filtersDataHelper, (newValue, oldVal) => {
      let change = false;
      
      const setFilter = newValue || {};
      const currentFilter = oldVal || {};

      for (const key in newValue) {
        if (!Array.isArray(currentFilter[key])) {
          change = true;

          currentFilter[key] = [];
        }

        if (!change && currentFilter[key][0] != setFilter[key][0]) {
          change = true;
        }
      }

      if (change) {
        emit('filters-data', newValue)
        
      }
    });
    // subscription
    vm.$apollo.addSmartSubscription('getFiltersDataBpu', {
      query: gql`
        subscription getFiltersDataBpu {
          bpuOptions: BD_PreSimulador(distinct_on: bpu) {
            label: bpu
            value: bpu
          }
        }
      `,
      result({ data }) {
        bpuOptions.value = data.bpuOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataBrandCategory', {
      query: gql`
        subscription getFiltersDataBrandCategory {
          brandCategoryOptions: BD_PreSimulador(distinct_on: brand_category) {
            label: brand_category
            value: brand_category
          }
        }
      `,
      result({ data }) {
        brandCategoryOptions.value = data.brandCategoryOptions
      },
    })
    

    vm.$apollo.addSmartSubscription('getFiltersDataApplicationForm', {
      query: gql`
        subscription getFiltersDataApplicationForm {
          applicationFormOptions: BD_PreSimulador(distinct_on: application_form) {
            label: application_form
            value: application_form
          }
        }
      `,
      result({ data }) {
        applicationFormOptions.value = data.applicationFormOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataCanal', {
      query: gql`
        subscription getFiltersDataCanal {
          canalOptions: BD_PreSimulador(distinct_on: canal) {
            label: canal
            value: canal
          }
        }
      `,
      result({ data }) {
        canalOptions.value = data.canalOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataClasificacion', {
      query: gql`
        subscription getFiltersDataClasificacion {
          clasificacionOptions: BD_PreSimulador(distinct_on: clasificacion) {
            label: clasificacion
            value: clasificacion
          }
        }
      `,
      result({ data }) {
        clasificacionOptions.value = data.clasificacionOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataYear', {
      query: gql`
        subscription getFiltersDataYear {
          yearOptions: BD_PreSimulador(distinct_on: year) {
            label: year
            value: year
          }
        }
      `,
      result({ data }) {
        yearOptions.value = data.yearOptions
      },
    })

    vm.$apollo.addSmartSubscription('getFiltersDataMonth', {
      query: gql`
        subscription getFiltersDataMonth {
          monthOptions: BD_PreSimulador(distinct_on: month) {
            label: month
            value: month
          }
        }
      `,
      result({ data }) {
        monthOptions.value = data.monthOptions
      },
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      newFiltersData,
      onSubmit,
      showFilterSidebar,

      bpuOptions,
      brandCategoryOptions,
      applicationFormOptions,
      canalOptions,
      clasificacionOptions,
      yearOptions,
      monthOptions,
      monedaOptions,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

#filters-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
