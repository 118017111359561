<script>
import { Line, Bar, mixins } from 'vue-chartjs'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'

const { reactiveProp } = mixins

const totalizer = {
  id: 'totalizer',
  beforeUpdate: function (chart) {
    var totals = [];
    var utmost = {};
    chart.data.datasets.forEach(function (dataset, datasetIndex) {
      if (chart.isDatasetVisible(datasetIndex)) {
        var stack = dataset.stack;
        utmost[stack] = datasetIndex;
        dataset.data.forEach(function (value, index) {
          if (totals[index] === undefined) {
              totals[index] = {};
          }
          totals[index][stack] = (totals[index][stack] || 0) + value;
        })
      }
    })
    chart.$totalizer = {
        totals: totals,
        utmost: utmost
    }
  }
}



const chartSpace = {
  id: 'chartSpace',
  beforeInit: function(chart) {
    chart.legend.afterFit = function() {
      this.height = this.height + 5;
    };
  }
}

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null
    }
  },
  methods: {
    text() {
      return this.$data;
    }
  },
  mounted () {
    //this.addPlugin(ChartJsPluginDataLabels)
    //this.addPlugin(totalizer)
    this.addPlugin(chartSpace)

    this.chartInstance = this.renderChart(this.chartData, this.options)
  }
}
</script>

<style>
</style>