<template>
  <b-card
    no-body
    class="p-1"
  >
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <div
      id="pivot-container-bpu"
      ></div>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard
} from 'bootstrap-vue'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import gql from 'graphql-tag'

export default {
  components: {
    BCard,

    CustomLoading,
  },
  props: {
    filtersData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isLoading: true,
      pivotRef: null,
      repotDefaultSlice: {
        "rows": [
          {
            "uniqueName": "year"
          },
          {
            "uniqueName": "canal"
          }
        ],
        "columns": [
          {
            "uniqueName": "quarter"
          },
          {
            "uniqueName": "month"
          },
          {
            "uniqueName": "Measures"
          }
        ],
        "measures": [
          {
            "uniqueName": "netsales",
            "aggregation": "sum",
          }
        ]
      },
      report: {
        "dataSource": {
          "data": ''
        },
        "slice": {
          "rows": [
            {
              "uniqueName": "year"
            },
            {
              "uniqueName": "canal"
            }
          ],
          "columns": [
            {
              "uniqueName": "quarter"
            },
            {
              "uniqueName": "month"
            },
            {
              "uniqueName": "Measures"
            }
          ],
          "measures": [
            {
              "uniqueName": "netsales",
              "aggregation": "sum",
            }
          ]
        },
        "formats": [
          {
            "name": "",
            "thousandsSeparator": " ",
            "decimalSeparator": ".",
            "decimalPlaces": 0,
            "currencySymbol": "",
            "currencySymbolAlign": "right",
            "nullValue": "",
            "textAlign": "right",
            "isPercent": false
          }
        ]
      }
    }
  },
  watch: {
    filtersData: function(newVal, oldVal) {
      this.isLoading = true
      this.filtersData = newVal
    }
  },
  methods: {
    createCustomPivot() {
      this.pivotRef = new WebDataRocks({
        container: "#pivot-container-bpu",
        beforetoolbarcreated: customizeToolbar,
        report: this.report,
        toolbar: true
      })

      function customizeToolbar(toolbar) {
        const tabs = toolbar.getTabs() // get all tabs from the toolbar
        toolbar.getTabs = function() {
          delete tabs[0] // delete the first tab
          return tabs
        }
      }
    },
  },
  created() {
    this.isLoading = true
  },
  apollo: {
    $subscribe: {
      DemandSimulation: {
        query: gql`
          subscription get_DemandSimulation($customWhere: json = null) {
            function_get_bd_demand_simulation(args: {customWhere: $customWhere}) {    
              canal
              bpu
              brand_category
              application_form
              year
              month
              quarter
              units
              netsales
            }
          }
        `,
        variables(){
          return {
            customWhere: this.filtersData
          }
        },
        result ({data}) {
          let dataPivot = data.function_get_bd_demand_simulation
          if(dataPivot.length == 0) dataPivot = ''

          // remove _typename
          for(let obj of dataPivot) {
            delete obj.__typename
          }

          this.isLoading = false

          // update pivot table data
          this.report.dataSource.data = dataPivot
          if(this.pivotRef != null) {
            if( this.pivotRef.getColumns().length == 0
            || this.pivotRef.getRows().length == 0
            || this.pivotRef.getMeasures().length == 0) {
              this.report.slice = this.repotDefaultSlice
            } else {
              this.report.slice = this.pivotRef.getReport().slice
            }
          }
          this.$nextTick(() => {
            this.createCustomPivot()
          })
        },
      }
    }
  }
}
</script>

<style>
#wdr-pivot-view a.wdr-ui-btn {
  color: black;
}

#wdr-toolbar-wrapper div.wdr-panel .wdr-toolbox .wdr-ui-btn {
  color: black;
}
</style>
