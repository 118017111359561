<template>
  <b-card no-body>

    <sop-fc-modal></sop-fc-modal>

    <div v-if="isLoading" class="my-3">
      <custom-loading></custom-loading>
    </div>
    <div v-else>
      <b-card-body>

        <div :style="showTotals ? { 'visibility': '' } : { 'visibility': 'hidden' }">
          <span class="mr-1"><strong>{{ 'Suma: ' + gridSum }}</strong></span>
          <span><strong>{{ 'Promedio: ' + gridAvg }}</strong></span>
        </div>
        <v-grid ref="refGridFc" :source="source" :columns="columns" :grouping="groupConfig" :columnTypes="pluginRevo"
          theme="material" resize="true" autoSizeColumn=true range=true class="grid-container-fc" style="max-height: 14vh;"
          @beforeeditstart="beforeEditStart" @beforerangeedit="beforeRangeEdit" @beforeaange="beforeRange"
          @mouseup="onMouseUp"></v-grid>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BCardBody,
  BRow,
} from 'bootstrap-vue'
import { ref, getCurrentInstance, provide, watch, toRefs, computed } from 'vue'
import VGrid from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import SopFcModal from './SopFcModal.vue'

import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,

    CustomLoading,
    SopFcModal,

    VGrid,
  },
  props: {
    filtersData: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    // props
    const { filtersData } = toRefs(props)

    const source = ref([])
    const isLoading = ref(true)
    const refGridFc = ref(null)
    const groupConfig = ref({})
    const columns = ref([])
    const selectedCell = ref(null)
    const pluginRevo = { 'numeric': new NumberColumnType('0,0') }
    const gridSum = ref(0)
    const gridAvg = ref(0)

    const rangeSelected = ref({})

    const vm = getCurrentInstance().proxy

    // provide
    provide('selectedCell', selectedCell)
    provide('filtersData', filtersData)

    // computed
    const showTotals = computed(() => {
      return gridSum.value > 0 && gridSum.value > 0
    })

    //methods
    const beforeEditStart = (event) => {
      event.preventDefault()
      selectedCell.value = event.detail

      if (selectedCell.value.model.year.includes("-M") || selectedCell.value.model.year.includes("-m")) return

     // if (selectedCell.value.model.clasificacion.toLowerCase().includes('reales')) return

      vm.$bvModal.show('fc-detail-modal')
    }

    const beforeRange = (event) => {
      rangeSelected.value = event.detail.newRange
    }

    const beforeRangeEdit = async (event) => {
      event.preventDefault()

      const viewData = await refGridFc.value.$el.getVisibleSource()

      let totalSum = 0
      let totalCount = 0


      //console.log ('seleccion',rangeSelected.value)

      // verificar si es columna o no
      let isColumnSum = rangeSelected.value.x === rangeSelected.value.x1 && rangeSelected.value.y !== rangeSelected.value.y1
      let isRowSum  = rangeSelected.value.y === rangeSelected.value.y1 && rangeSelected.value.x !== rangeSelected.value.x1

      //Para x=0 , y=0  hasta x1=0 , y1=1
      if (isColumnSum) {
        for (let i = rangeSelected.value.y; i <= rangeSelected.value.y1; i++) { // for (let i= 0 ; i<=1; i++)
          const rowObject = viewData[i]                                         //x:0, y:0  [0,0,0,0,8759,10000....]
          const rowData = Object.values(rowObject)                              //x:0, y:1  [10890,10890,10890....]

        //  console.log('parametros ', rowData, rowData.length)
          if (rowData.length > 12) { // check si es la fila con datos , lenght = 15
            totalSum += rowData[rangeSelected.value.x + 1]
            totalCount++
          }
      
        //  console.log('funcion suma en if trae ', totalSum)
        }
      }       
      if (isRowSum) {
      
      for (let i = rangeSelected.value.x + 1 ; i <= rangeSelected.value.x1 + 1 ; i++) { // for (let i= 0 ; i<=0; i++)
          const rowObject = viewData[rangeSelected.value.y]
          const rowData = Object.values(rowObject)

        //  console.log('parametros ',rowObject, rowData, rowData.length)
        if (rowData.length > 12) { // check si es la fila con datos|
            totalSum += rowData[i]
            totalCount++          
        }
      //    console.log("valor de i", i)
       // console.log('funcion suma en else trae ',totalSum)

        }
      }
  
      gridSum.value = (totalSum).toFixed(2)
      gridAvg.value = (totalSum / totalCount).toFixed(2)
   

    }

    const onMouseUp = async (event) => {
      event.preventDefault()
      rangeSelected.value = {}
      gridSum.value = 0
      gridAvg.value = 0
    }



    const getStyleGrid = (prop, model) => {
      // if (!model.clasificacion.toLowerCase().includes('reales')) return
      let dateObj = new Date();
      let monthNumber = dateObj.getUTCMonth() + 1; //months from 1-12
      let year = dateObj.getUTCFullYear();

      let propMonth = parseInt(prop.split('_')[1])
      let propYear = model.year
      if (propYear > year) {
        return {
          "text-align": "right",
          background: "#D2E8FF"
        }
      } else if (propYear == year) {
        if (propMonth >= monthNumber || prop == "total") {
          return {
            "text-align": "right",
            background: "#D2E8FF"
          }
        }
      }
      return {
        "text-align": "right",
      }
    }

    // config table
   /*
    groupConfig.value = {
      props: ['clasificacion'],
      expandedAll: true,
    }
*/
    columns.value = [
      {
        "name": "Forecast",
        "prop": "year",
        "size": 120,
        "autoSize": true,
        "readonly": true,
        "pin": "colPinStart"
      },
      {
        "name": "Ene",
        "prop": "mes_01",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "Feb",
        "prop": "mes_02",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "Mar",
        "prop": "mes_03",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "Abr",
        "prop": "mes_04",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "May",
        "prop": "mes_05",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "Jun",
        "prop": "mes_06",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "Jul",
        "prop": "mes_07",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "Ago",
        "prop": "mes_08",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "Sep",
        "prop": "mes_09",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "Oct",
        "prop": "mes_10",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "Nov",
        "prop": "mes_11",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "Dic",
        "prop": "mes_12",
        "size": 80,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
      {
        "name": "Total",
        "prop": "total",
        "size": 100,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model),
          }
        },
      },
    ]

    watch(filtersData, (newValue, oldValue) => {
      isLoading.value = true
      filtersData.value = newValue
    })

    // subscription
    vm.$apollo.addSmartSubscription('getSimuladorFC', {
      query: gql`
        subscription getSimuladorFC($customWhere: json = null) {
          function_get_simulador_fc(args: {customWhere: $customWhere} ) {
           
            year
            mes_01
            mes_02
            mes_03
            mes_04
            mes_05
            mes_06
            mes_07
            mes_08
            mes_09
            mes_10
            mes_11
            mes_12
            total: Total
          }
        }
      `,
      variables() {
        return {
          customWhere: filtersData.value
        }
      },
      result({ data }) {
        console.log('datadeforecast',data)
        isLoading.value = false
        source.value = data.function_get_simulador_fc
      },
    })

    return {
      groupConfig,
      source,
      columns,
      isLoading,
      refGridFc,
      pluginRevo,
      gridSum,
      gridAvg,
      showTotals,

      beforeRange,
      beforeEditStart,
      beforeRangeEdit,
      onMouseUp
  
    }
  },
}
</script>

<style lang="scss">
.grid-container-fc {
  max-width: 1360px;
  width: 100%;
  height: 70vh;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
