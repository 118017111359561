<template>
  <b-card no-body style=" max-height: fit-content; padding-top: -5rem;">

    <div v-if="isLoading" class="my-3">
      <custom-loading></custom-loading>
    </div>
    <div v-else>
      <!-- <div class="line"></div> -->
      <b-card-body style="padding-top: 2rem;padding-bottom: 1rem;">
        <div>
          <label id="tittle" style="font-size: medium;">FC Comparative</label>
        </div>
       <b-popover target="tittle" placement="right" triggers="hover">Cuadro comparativo REALES+PROY (LY, L2Y y CY) vs Forecast</b-popover>

        <div style="text-align: end;">
          <span :style="showTotals ? { 'visibility': '', 'text-align': 'end' } : { 'visibility': 'hidden' }" class="mr-1">
            <strong>{{ 'Sum: ' + sumAbv }}</strong></span>
          <span :style="showTotals ? { 'visibility': '', 'text-align': 'end' } : { 'visibility': 'hidden' }"><strong>{{
            'Avg: ' + avgAbv }}</strong></span>
          <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; *Amount in thousands</strong>
        </div>

        <b-row>
          <div>
            <b-dropdown id="filtro-ocultar"  text="Filtro Ocultar" ref="dropdown" style="width: 145px;background-color: #0032a0;color: white; margin-bottom: 2rem;margin-left: 1rem;"  variant="outline-light">
              <b-dropdown-form>
                <b-form-group>
                  <b-form-checkbox-group :options="allColumns" v-model="visibleColumns">
                  </b-form-checkbox-group>
                </b-form-group>
              </b-dropdown-form>
            </b-dropdown>
          </div>
          <div style="margin-left: 1rem;">
            <b-input-group size="lg" prepend="€">
              <b-form-input v-model="inputEuro" placeholder="Ingresa el TC"></b-form-input>
              <b-input-group-append>
                <b-button id="btn-check2" @click="changeFormat" variant="success"><b-icon-check2/></b-button>
                <b-popover target="btn-check2" placement="top" triggers="hover">Cambiar a €</b-popover>
                <b-button id="btn-reset" @click="resetFormat" variant="black" style="background-color: #0032a0;"><b-icon-arrow-counterclockwise style="color: white;"/></b-button>
                <b-popover target="btn-reset" placement="top" triggers="hover">Volver a S/.</b-popover>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-row>

        <v-grid ref="refGridFc" :source="processedSource" :columns="filteredColumns" :grouping="groupConfig" :columnTypes="pluginRevo"
          theme="material" resize="true" autoSizeColumn=true range=true class="grid-container-sellinproy"
          style="height: 65rem;" @beforeeditstart="beforeEditStart" @beforerangeedit="beforeRangeEdit"
          @beforeaange="beforeRange" @mouseup="onMouseUp">
        </v-grid>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BCardBody,BInputGroup,BFormInput,BInputGroupAppend,BIconCheck2,BIconArrowCounterclockwise,
  BRow,BDropdownDivider,BPopover, BFormCheckboxGroup, BFormCheckbox, BDropdown, BDropdownForm, BFormGroup,BootstrapVue
} from 'bootstrap-vue'
import { ref, getCurrentInstance, provide, watch, toRefs, computed } from 'vue'
import VGrid from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'
import { data } from 'vue-echarts'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,
    CustomLoading,
    VGrid,
    BDropdownDivider,
    BPopover,
    BFormCheckboxGroup,
    BFormCheckbox,
    BDropdown,
    BDropdownForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BIconCheck2,
    BIconArrowCounterclockwise,
    BootstrapVue
},
  props: {
    filtersData: {
      type: Object,
      default: null
    },
    dimension: true
  },
  // ??
  setup(props) {
    // props
    const { filtersData } = toRefs(props)
    const abbreviate = require('number-abbreviate')
    const source = ref([])
    const isLoading = ref(true)
    const refGridFc = ref(null)
    const groupConfig = ref({})
    const columns = ref([])
    const selectedCell = ref(null)
    const pluginRevo = {
      'numeric': new NumberColumnType('0,0[.]0'),
      'percentage': new NumberColumnType('0,0[.]00 %'),
      'numeric_negative': new NumberColumnType('(0,0)'),
    };
    const gridSum = ref(0)
    const gridAvg = ref(0)
    const sumAbv = ref(null)
    const avgAbv = ref(null)
    let arregloColumnas = ref([])
    let inputEuro = ref(null)
    const rangeSelected = ref({})
    const originalSource = ref([]);
    const vm = getCurrentInstance().proxy

    let current_year = ref(new Date().getFullYear());
    let lastyear = ref(current_year.value - 1)
    let nextyear = ref(current_year.value + 1)

    const cellStyle = {
      CY_vs_LY:true,
      FC_CY_vs_LY:true,
      FC_NY_vs_CY:true,
      SOPM1_CY_vs_CY:true,
      SOPM1_NY_vs_NY:true,
      NY_vs_CY: true,
      ABP_CY_vs_CY: true,
      ABP_NY_vs_CY: true,
    }

    // provide  -  no se usa
    provide('selectedCell', selectedCell)
    provide('filtersData', filtersData)

    // computed
    const showTotals = computed(() => {
      return gridSum.value > 0 && gridSum.value > 0

    })

    //methods
    const beforeEditStart = (event) => {
      event.preventDefault()
      selectedCell.value = event.detail
    }

    const beforeRange = (event) => {
      rangeSelected.value = event.detail.newRange
    }

    const beforeRangeEdit = async (event) => {
      event.preventDefault()

      const viewData = await refGridFc.value.$el.getVisibleSource()

      let totalSum = 0
      let totalCount = 0

      //console.log ('seleccion',rangeSelected.value)

      // verificar si es columna o no
      let isColumnSum = rangeSelected.value.x === rangeSelected.value.x1 && rangeSelected.value.y !== rangeSelected.value.y1
      let isRowSum = rangeSelected.value.y === rangeSelected.value.y1 && rangeSelected.value.x !== rangeSelected.value.x1

      //Para x=0 , y=0  hasta x1=0 , y1=1
      if (isColumnSum) {
        for (let i = rangeSelected.value.y; i <= rangeSelected.value.y1; i++) { // for (let i= 0 ; i<=1; i++)
          const rowObject = viewData[i]                                         //x:0, y:0  [0,0,0,0,89,10000....]
          const rowData = Object.values(rowObject)                              //x:0, y:1  [10890,10890,10890....]

          //  console.log('parametros ', rowData, rowData.length)
          if (rowData.length > 25) { // check si es la fila con datos , lenght = 15
            totalSum += rowData[rangeSelected.value.x + 2]
            totalCount++
          }

          //  console.log('funcion suma en if trae ', totalSum)
        }
      }
      if (isRowSum) {

        for (let i = rangeSelected.value.x + 1; i <= rangeSelected.value.x1 + 1; i++) { // for (let i= 0 ; i<=0; i++)
          const rowObject = viewData[rangeSelected.value.y]
          const rowData = Object.values(rowObject)

          //console.log('parametros ',rowObject, rowData, rowData.length)
          if (rowData.length > 25) { // check si es la fila con datos|
            totalSum += rowData[i + 1]
            totalCount++
          }
          // console.log("valor de i", i)
          //console.log('funcion suma en else trae ',totalSum)

        }
      }

      gridSum.value = (totalSum).toFixed(2)
      sumAbv.value = abbreviate(gridSum.value)
      // console.log('la suma abreviada es ',sumAbv)
      gridAvg.value = (totalSum / totalCount).toFixed(2)
      avgAbv.value = abbreviate(gridAvg.value)


    }

    const onMouseUp = async (event) => {
      event.preventDefault()
      rangeSelected.value = {}
      gridSum.value = 0
      sumAbv.value = 0
      gridAvg.value = 0
      avgAbv.value = 0
    }

// COLOR TRANSPARENTE EN LAS COLUMNAS DE LAS TABLAS
    const getStyleGrid = (prop, model, column) => {

      let customStyle = {
        "text-align": "center",
      }

      if (prop == "CY") {
        return {
          "text-align": "center",
          fontWeight: "bold",
          //color fondo
          background: "#F6F6F6",
        }

      }
      // if (prop == "QUARTER" || prop == "MONTH") {
      //   return {
      //     "text-align": "center",
      //   }
      // }

      if (model["MONTH"] == "TOTAL") {
          customStyle.background = "#F6F6F6"
          customStyle.fontWeight = "bold"
          if (prop === "QUARTER" || prop === "MONTH") {
            customStyle.fontWeight = "bold";
          }
      }


// CAMBIO DE COLOR DE LOS NÚMEROS DE %LY Y %FC
      if (cellStyle[prop]) {
        //console.log(cellStyle.Total_FC, cellStyle.Total_LY);
        customStyle["color"] = "black";
        customStyle["text-align"] = "center";
        customStyle["background"] = "#F6F6F6";
        customStyle["fontWeight"] = "bold";
        customStyle["display"] ="block";
        if (model[prop] > 0) {
          customStyle["color"] = "green";
          customStyle["text-align"] = "center";
          customStyle["background"] = "#F6F6F6";
          customStyle["fontWeight"] = "bold";
          customStyle["display"] ="block";
        }
        if (model[prop] < 0) {
          customStyle["color"] = "red";
          customStyle["text-align"] = "center";
          customStyle["background"] = "#F6F6F6";
          customStyle["fontWeight"] = "bold";
          customStyle["display"] ="block";
        }
      }


      return customStyle;
    }



 // no usa
    const ocultarColumnas = async ()=>{

      const viewData = await refGridFc.value.$el.getVisibleSource()

      console.log('refGrid',viewData)
    /*   let consulta;
      consulta = document.querySelectorAll('.q1');

      for(const el in consulta){
        console.log(consulta[el])
        consulta[el].style.display='none',
        consulta[el].style.width='none'
      }
*/

// no usa
    }
    const getFormatNumber = (prop, model) => {


      const dataparse = [];

      for (const l in model) {

        obj[l] = {};

        for (const a in model[l]) {
          //console.log('dentro del for',dataoriginal[l][a],a)

          obj[l][a] = a === 'quarter' ? model[l][a] : abbreviate(model[l][a])
        }

        dataparse.push(obj)

      }
 
    }


    arregloColumnas = [
      {
        "name": "Quarter",
        "prop": "QUARTER",
        "size": 80,
        "autoSize": true,
        "readonly": true,
        headerSlot: 'quarter-header',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
      },
      {
        "name": "Month",
        "prop": "MONTH",
        "size": 80,
        "autoSize": true,
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },

      },
      {
        "name": 'S&OP-M1 '+current_year.value,
        "prop": "SOPM1_CY",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
      },
      {
        "name": "S&OP-M1 "+nextyear.value,
        "prop": "SOPM1_NY",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
      },
      {
        "name": lastyear.value,
        "prop": "LY",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
            // columnType: getFormatNumber(prop, model)
          }
        },
      },
      {
        "name": current_year.value,
        "prop": "CY",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              background:'#2F56AA'
            },
            class: {
              'size': 10
            }
          };
        },
      },
      {
        "name": nextyear.value,
        "prop": "NY",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
            // columnType: getFormatNumber(prop, model)
          }
        },
      },
      {
        "name": "FC "+current_year.value,
        "prop": "FC_CY",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
      },
      {
        "name": "FC "+nextyear.value,
        "prop": "FC_NY",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
      },
      {
        "name": "ABP "+current_year.value,
        "prop": "ABP_CY",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
      },
      {
        "name": "ABP "+nextyear.value,
        "prop": "ABP_NY",
        "size": 120,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
      },
      {
        "name": current_year.value+' vs '+lastyear.value,
        "prop": "CY_vs_LY",
        "size": 120,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background:'#2F56AA'
            },
          };
        },
      },
      {
        "name": "FC "+current_year.value+" vs "+lastyear.value,
        "prop": "FC_CY_vs_LY",
        "size": 130,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background:'#2F56AA'
            },
          };
        },
      },
      {
        "name": "FC "+nextyear.value+" vs "+current_year.value,
        "prop": "FC_NY_vs_CY",
        "size": 130,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background:'#2F56AA'
            },
          };
        },
      },
      {
        "name": "S&OP-M1 "+current_year.value+" vs "+current_year.value,
        "prop": "SOPM1_CY_vs_CY",
        "size": 180,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background:'#2F56AA'
            },
          };
        },
      },
      {
        "name": "S&OP-M1 "+nextyear.value+" vs "+nextyear.value,
        "prop": "SOPM1_NY_vs_NY",
        "size": 180,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background:'#2F56AA'
            },
          };
        },
      },
      {
        "name": nextyear.value+" vs "+current_year.value,
        "prop": "NY_vs_CY",
        "size": 120,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background:'#2F56AA'
            },
          };
        },
      },
      {
        "name": "ABP "+current_year.value+" vs "+current_year.value,
        "prop": "ABP_CY_vs_CY",
        "size": 140,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background:'#2F56AA'
            },
          };
        },
      },
      {
        "name": "ABP "+nextyear.value+" vs "+current_year.value,
        "prop": "ABP_NY_vs_CY",
        "size": 140,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background:'#2F56AA'
            },
          };
        },
      },
    ]

    columns.value = arregloColumnas;

    watch(filtersData, (newValue, oldValue) => {
      isLoading.value = true
      filtersData.value = newValue
    })

    let allColumns = ref(columns.value.map(col => col.name));
    const visibleColumns = ref(allColumns.value);
    //console.log('filtro columnas: ',allColumns, visibleColumns)

    const filteredColumns = computed(() => {
      return columns.value.filter(col => visibleColumns.value.includes(col.name));
    });

    const changeFormat = () =>{
      if(inputEuro.value) {
        const conversion = parseFloat(inputEuro.value);
        source.value = source.value.map(row => {
          for (const key in row) {
            columns.value.forEach(col => {
              if (col.columnType === 'numeric' && col.prop === key && typeof row[key] === 'number') {
                row[key] = row[key] / conversion;
              }
            });
          }
          return row;
        });
      }
    }

    const resetFormat = () => {
      source.value = JSON.parse(JSON.stringify(originalSource.value));
      inputEuro.value = "";
    }

    const processedSource = computed(() => {
      return source.value.map((item, index, array) => {
        // Si es el primer elemento, siempre mostrar el quarter
        if (index === 0) return item;
        // Comparar con el elemento anterior
        if (item.QUARTER === array[index - 1].QUARTER) {
          return { ...item, QUARTER: '' };
        }
        return item;
      });
    });


    // subscription
    vm.$apollo.addSmartSubscription('getTotalComparative', {
      query: gql`
        subscription getTotalComparative ($customWhere: json = null){
            function_get_total_comparative(args: {customWhere: $customWhere}, order_by: {QUARTER: asc,MONTH: asc}) {
              QUARTER
              MONTH
              SOPM1_CY
              SOPM1_NY
              LY
              CY
              NY
              FC_CY
              FC_NY
              ABP_CY
              ABP_NY
              CY_vs_LY
              FC_CY_vs_LY
              FC_NY_vs_CY
              SOPM1_CY_vs_CY
              SOPM1_NY_vs_NY
              NY_vs_CY
              ABP_CY_vs_CY
              ABP_NY_vs_CY
            }
          }
      `,
      variables() {
        return {
          customWhere: filtersData.value
        }
      },
      result({ data }) {
        let dataoriginal = [];
        let dataparse = []
        isLoading.value = false
        const obj = {}
        dataoriginal = data.function_get_total_comparative

        //console.log('la data original', dataoriginal)

        /*  for (const l in dataoriginal) {
            obj[l] = {};

            for (const a in dataoriginal[l]) {
              //console.log('dentro del for',dataoriginal[l][a],a)

              obj[l][a] = a === 'year' ? dataoriginal[l][a] : abbreviate(dataoriginal[l][a])
            }

            dataparse.push(obj)

          }
          console.log('dataparse', dataparse)
  */
        source.value = dataoriginal
        originalSource.value = JSON.parse(JSON.stringify(dataoriginal));;
      },
    })

    return {
      groupConfig,
      source,
      columns,
      isLoading,
      refGridFc,
      pluginRevo,
      gridSum,
      gridAvg,
      sumAbv,
      avgAbv,
      showTotals,
      beforeRange,
      beforeEditStart,
      beforeRangeEdit,
      onMouseUp,
      allColumns,
      visibleColumns,
      filteredColumns,
      inputEuro,
      changeFormat,
      resetFormat,
      processedSource
    }
  },
}
</script>

<style lang="scss">
.grid-container-sellinproy {

  width: 100%;
  height: 400px;
  font-size: 11px;
  font-family: "Montserrat", Helvetica, Arial, serif !important;



  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .actual-rgRow {
    background: #0032a0;
    color: white;
  }

  .group-rgRow {
    .rgHeaderCell {
      color: white;
      background: #6783c0;
      word-wrap: break-word;
      word-break: break-all;
      .resizable {
        background: white;
      }
    }

  }
  .rgCell{
    padding: 0 2.5px;
  }
}

.line{
  border-top: 1px solid rgb(194, 194, 194);
  height: 0.2px;
  padding: 0;
  margin: 20px auto 0 auto;
}
.row-total{
  background-color: #F6F6F6;
}

.highlight-row{
  background-color: '#F6F6F6';
  font-weight: "bold";
}
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
