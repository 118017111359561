<template>
  <b-card
    no-body
  >
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <b-card-body>
        <v-grid
          ref="refGridSopBpuVariacion"
          :source="source" 
          :columns="columns" 
          :grouping="groupConfig"
          :columnTypes="pluginRevo"
          theme="material" 
          resize="true"
          autoSizeColumn = true
          class="grid-container-sop-bpu-variacion"
        ></v-grid>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BCardBody
} from 'bootstrap-vue'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import VGrid from '@revolist/vue-datagrid'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'

export default {
  components: {
    BCard,
    BCardBody,

    CustomLoading,
    VGrid,
  },
  props: {
    filtersData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isLoading: true,
      source: [],
      groupConfig: {
        props: ['year'],
        expandedAll: true,
      },
      pluginRevo: { 'percentage': new NumberColumnType('0,0[.]00 %'), },
      columns: [
        {
          "name": "Selling + Proxy",
          "prop": "canal",
          "size": 120,
          "autoSize": true,
          "readonly": true,
          "pin": "colPinStart",
          cellProperties: ({ prop, model, data, column }) => {
            return {
              style: this.getStyleGrid(prop, model, column),
            }
          },
        },
        {
          "name": "Q1",
          "children": [
            {
              "name": "NS",
              "prop": "Enero_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "%vs LY",
              "prop": "Febrero_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "% vs FC",
              "prop": "Marzo_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "TOTAL",
              "prop": "Q1_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
          ]
        },
        {
          "name": "Q2",
          "children": [
            {
              "name": "NS",
              "prop": "Abril_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "Mayo",
              "prop": "Mayo_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "Junio",
              "prop": "Junio_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "TOTAL",
              "prop": "Q2_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
          ]
        },
        {
          "name": "Q3",
          "children": [
            {
              "name": "Julio",
              "prop": "Julio_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "Agosto",
              "prop": "Agosto_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "Septiembre",
              "prop": "Setiembre_netsales",
              "size": 120,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "TOTAL",
              "prop": "Q3_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
          ]
        },
        {
          "name": "Q4",
          "children": [
            {
              "name": "Octubre",
              "prop": "Octubre_netsales",
              "size": 100,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "Noviembre",
              "prop": "Noviembre_netsales",
              "size": 120,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "Diciembre",
              "prop": "Diciembre_netsales",
              "size": 110,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
            {
              "name": "TOTAL",
              "prop": "Q4_netsales",
              "size": 90,
              "autoSize": true,
              "columnType": 'percentage',
              cellProperties: ({ prop, model, data, column }) => {
                return {
                  style: this.getStyleGrid(prop, model, column),
                }
              },
            },
          ]
        },
        {
          "name": "TOTAL Canal",
          "prop": "Total_netsales",
          "size": 150,
          "autoSize": true,
          "columnType": 'percentage',
        },
      ],
    }
  },
  methods: {
    getStyleGrid (prop, model, column) {
      let customStyle = {
        "text-align": "right"
      }

      if (column.prop == "year"
        || column.prop == "canal") {
        delete customStyle["text-align"]
      }


      if(model.canal.toLowerCase() == "year total") {
        customStyle["background"] = "#a6a6a6"
        customStyle["color"] = "white"
        return customStyle
      }

      if(column.prop.charAt(0).toLowerCase() == "q" 
      ) {
        customStyle["background"] = "#DAE3F3"
        return customStyle
      }

      return customStyle
    }
  },
  watch: {
    filtersData: function(newVal, oldVal) {
      this.isLoading = true
      this.filtersData = newVal
    }
  },
  created() {
    this.isLoading = true
  },
  apollo: {
    $subscribe: {
      VariacionPorcentual: {
        query: gql`
          subscription get_VariacionPorcentual($customWhere: json = null) {
            function_get_variacion_porcentual_tot(args: {customWhere: $customWhere})  {
              year
              canal
              Enero_netsales
              Febrero_netsales
              Marzo_netsales
              Q1_netsales
              Abril_netsales
              Mayo_netsales
              Junio_netsales
              Q2_netsales
              Julio_netsales
              Agosto_netsales
              Setiembre_netsales
              Q3_netsales
              Octubre_netsales
              Noviembre_netsales
              Diciembre_netsales
              Q4_netsales
              Total_netsales
            }
          }
        `,
        variables(){
          return {
            customWhere: this.filtersData
          }
        },
        result ({data}) {
          this.source = data.function_get_variacion_porcentual_tot
          this.isLoading = false
        },
      }
    }
  }
}
</script>

<style lang="scss">
.grid-container-sop-bpu-variacion {
  max-width: 1700px;
  width: 100%;
  height: 70vh;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .actual-rgRow {
    background: #0032a0;
    color: white;
  }

  .group-rgRow {
    .rgHeaderCell {
      color: white;
      background: #6783c0;

      .resizable {
        background: white;
      }
    }

  }
}
</style>


