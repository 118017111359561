<template>
  <b-card id="cardprincipal">
    <simulacion-filters :filtersData="filter" :is-filters-simulador-active.sync="isFiltersSimuladorActive"
      @filters-simulador-data="data => setFiltersSimuladorData(data)" />
      

    <b-card-body style="padding-right: 0.1rem;padding-bottom: 0rem;">
      <div>
          <label style="font-size: medium; padding-bottom: 1rem;">Simulator S&OP</label>
      </div>
      <!--
      <span v-on:click="() => showTop = !showTop" style="cursor: pointer;">
        <span style="color: blue; font-size: 18px;">&#8680;</span> Show
      </span>
      <b-row v-show="showTop" class="mb-1">
      -->
      <b-container style="height: 100%;">
        <b-row>
          <b-container style="width: 100%;">
            <b-row style="width: 103%; height: 1.5rem;  padding-right: 0rem; padding-left: 0rem;background-color: rgb(233, 233, 233);  border-top-right-radius: 0.5rem; border-top-left-radius: 0.5rem;">
              <b-col cols="2" style="padding-right: 0rem;padding-left: 0.5rem;">
                <div v-if="filter.year" style="font-size: 9px;padding-right: 0rem;padding-left: 0rem;"><strong>YEAR:
                  </strong>
                  {{ filter.year[0] }}</div>
              </b-col>
              <b-col cols="3" style="padding-right: 0rem;padding-left: 0rem;">
                <div v-if="filter.canal" style="font-size: 9px;padding-right: 0rem;padding-left: 0rem;"><strong>CANAL:
                  </strong> {{
                    filter.canal[0] }}</div>
              </b-col>
              <b-col cols="3" style="padding-right: 0rem;padding-left: 0rem;">
                <div v-if="filter.clasificacion" style="font-size: 9px;padding-right: 0rem;padding-left: 0rem;">
                  <strong>TYPE: </strong> {{
                    filter.clasificacion[0] }}</div>
              </b-col>
              <b-col cols="4" style="padding-right: 0rem;padding-left: 0rem;">
                <div v-if="filter.bpu" style="font-size: 9px;padding-right: 0rem;padding-left: 0rem;"><strong>BPU:
                  </strong> {{ filter.bpu[0] }}</div>
              </b-col>
            </b-row>
            <b-row style="width: 103%; height: 1.5rem; background-color: rgb(233, 233, 233)">
              <b-col cols="2" style="padding-right: 0rem;padding-left: 0.5rem;">
                <div v-if="filter.month" style="font-size: 9px;"><strong>MONTH: </strong> {{
                  filter.month[0] }}</div>
              </b-col>
              <b-col cols="5" style="padding-right: 0rem;padding-left: 0rem;">
                <div v-if="filter.brand_category" style="font-size: 9px;padding-right: 0rem;padding-left: 0rem;">
                  <strong>BRAND CAT:
                  </strong>
                  {{ filter.brand_category[0] }}</div>
              </b-col>
              <b-col cols="5">
                <div v-if="filter.application_form" style="font-size: 9px;padding-right: 0rem;padding-left: 0rem;">
                  <strong>APP.FORM:
                  </strong>
                  {{ filter.application_form[0] }}</div>
              </b-col>
            </b-row>
            <b-row style="padding-bottom: 0.5rem;padding-left: 0rem; padding-top: 1rem;">
              <b-col cols="6" style="padding-left: 0rem;">
                <label style="font-size: 1rem;">New Netsales</label>
                <b-input-group size="sm">
                  <b-form-input placeholder="Enter the new value ns"  v-model="ajustes">
                  </b-form-input>
                  <b-input-group-append style="padding-left: 0.5rem;">
                    <b-button pill id="btn-check" class="btn-check" @click="submit" variant="pink" type="submit">
                      <b-icon-check2/>
                    </b-button>
                    <b-popover target="btn-check" placement="topright" triggers="hover">Aplica un nuevo valor de NetSales(New NS) a todos los valores filtrados previamente con el botón "Simulation Data Filter"</b-popover>
                  </b-input-group-append>
                  <b-input-group-append style="padding-left: 0.5rem;">
                    <b-button pill id="btn-clear" class="btn-clear" @click="clearBoxAjustes" variant="pink" size="sm">
                      <b-icon-trash-fill/>
                    </b-button>
                    <b-popover target="btn-clear" placement="topright" triggers="hover">Limpia el cuadro de New Netsales.</b-popover>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="6" style="padding-right: 0rem;">
                <label style="font-size: 1rem;">Comments</label>
                <b-input-group size="sm">
                  <b-form-input placeholder="Enter a new comment" v-model="comentarios">
                  </b-form-input>
                  <b-input-group-append style="padding-left: 0.5rem;">
                    <b-button pill id="btn-check-com" class="btn-check" @click="comment" variant="pink" type="submit">
                      <b-icon-check2/>
                    </b-button>
                    <b-popover target="btn-check-com" placement="topright" triggers="hover">Asigna un comentario(Comments) a todos los valores filtrados previamente con el botón "Simulatio Data Filter"</b-popover>
                  </b-input-group-append>
                  <b-input-group-append style="padding-left: 0.5rem;">
                    <b-button pill id="btn-clear-com" class="btn-clear" @click="clearBoxComment" variant="pink" size="sm">
                      <b-icon-trash-fill/>
                    </b-button>
                    <b-popover target="btn-clear-com" placement="topright" triggers="hover">Limpia el cuadro de Comments</b-popover>
                  </b-input-group-append>
                </b-input-group>
              </b-col>


            </b-row>
            <b-row class="flex-column flex-md-row" style="padding-top: 1rem; padding-bottom: 1rem;">
              
              
                <b-button id="btn-simulator" class="btn-simulator" @click="isFiltersSimuladorActive = true" variant="pink" size="sm">
                  <b-icon-sliders/> Simulation Data Filter
                </b-button>
                <b-popover target="btn-simulator" placement="topright" triggers="hover">Filtro exclusivo para el cuadro del Simulador</b-popover>
              

              <b-col class="flex-grow-1"></b-col>

              <b-col>
                <b-row class="justify-content-end flex-row">
                  <div >
                    <b-button id="btn-save" class="btn-save" @click="saveData" :disabled="isDisabledSaveData" variant="pink" size="sm">
                      <b-icon-arrow-down-circle-fill/> Save Table
                    </b-button>
                    <b-popover target="btn-save" placement="topright" triggers="hover">Guarda los cambios realizados de New NetSales y Comments.(Post Simulacion).</b-popover>
                  </div>

                  <div style="padding-left: 0.5rem;">
                    <b-button id="btn-rest" class="btn-rest" @click="cargarData" variant="pink" size="sm">
                      <b-icon-arrow-counterclockwise/> Restore Data
                    </b-button>
                    <b-popover target="btn-rest" placement="topright" triggers="hover">Deshace los cambios realizados y reestablece la data de "Database".</b-popover>
                  </div>
                </b-row>
              </b-col>
            </b-row>

          </b-container>
        </b-row>
        <b-row style="height: 100%">
          <div v-if="isLoading" class="my-3">
            <custom-loading></custom-loading>
          </div>
          <v-grid ref="refGrid" :source="source" :columns="columns" theme="material" resize="true" autoSizeColumn=true
            range="true" :columnTypes="pluginRevo" class="grid-container-tabla-simulacion"
            @beforeeditstart="beforeEditStart" @focusout="commitCell"></v-grid>
        </b-row>
      </b-container>
      <!--</b-row>-->
    </b-card-body>

  </b-card>
</template>
  
<script>
import {
  BCard, BButton, BCardBody, BCol,BPopover, BIconArrowDownCircleFill, VBTooltip,
  BRow, BInputGroup, BFormInput, BInputGroupAppend, BContainer, BButtonGroup, BIcon,BIconCheck2,BIconTrashFill,BIconSliders,BIconDownload,BIconArrowCounterclockwise
} from 'bootstrap-vue'
import { ref, getCurrentInstance, computed, toRefs, watch, provide } from 'vue'
import VGrid from '@revolist/vue-datagrid';
import axios from '@axios'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import SimulacionFilters from './SimulacionFilters.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'
import { numericSort } from '@/libs/revo-grid/custom-sort'
import { async } from 'q';


export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCol,
    BCardBody,
    BInputGroup,
    BFormInput,
    BContainer,
    BButtonGroup,
    BInputGroupAppend,
    CustomLoading,
    VGrid,
    SimulacionFilters,
    BIcon,
    BIconCheck2,
    BIconTrashFill,
    BIconSliders,
    BIconDownload,
    BIconArrowCounterclockwise,
    BPopover,
    BIconArrowDownCircleFill,
    VBTooltip
  },
  data() {

    return {
      isFiltersSimuladorActive: false,
      showTop: false
    }
  },
  methods: {
    setFiltersSimuladorData(data) {

      //console.log('filterss', this.filter);

      //console.log('data', data);

      let change = false;

      const setFilter = Object.assign({}, data);
      const currentFilter = { ...this.filter };

      for (const key in setFilter) {
        if (!Array.isArray(currentFilter[key])) {
          change = true;

          currentFilter[key] = [];
        }

        if (!change && currentFilter[key][0] != setFilter[key][0]) {
          change = true;
        }
      }

      if (change) {
        this.filter = setFilter;
      }
    }
  },
  props: {
    filtersData: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const { filtersData } = toRefs(props);
    const filter = ref({
      bpu: [],
      brand_category: [],
      application_form: [],
      canal: [],
      clasificacion: [],
      year: [],
      month: []
    });
    const source = ref([])
    const isLoading = ref(true)
    const refGrid = ref(null)
    const columns = ref([])
    const revoRowIndex = ref(null)
    const revoColName = ref(null)
    const modifiedData = ref([])
    const selectedCell = ref(null)
    let ajusteNS = ref(null)
    let comentario = ref(null)
    const ajustes = ref('')
    const comentarios = ref('')
    let cabecera = ref(null)
    let lastID = ref({})
    const pluginRevo = { 'numeric': new NumberColumnType('0,0') }

    const el = ref(null)


    const vm = getCurrentInstance().proxy

    provide('selectedCell', selectedCell)

    provide('ajusteNS', ajusteNS)

   

    const joinGlobalFilterValue = () => {


      const globalFilter = filtersData.value;
      const localFilter = { ...filter.value };

      console.log('filtersData trae ', globalFilter)

      for (const key in globalFilter) {
        if (!localFilter[key]) localFilter[key] = globalFilter[key] || [];

        if (globalFilter[key].length > 0) localFilter[key] = globalFilter[key] || [];

        if (globalFilter[key].length == 0) localFilter[key] = globalFilter[key];
      }

      filter.value = localFilter;
    }

    const submit = async () => {

      ajusteNS = ajustes.value

      const originalData = await refGrid.value.$el.getSource()

      // console.log('se ejecuto submit', ajusteNS)


      for (const rp of originalData) {
        rp['ns_new'] = ajusteNS

      }
      console.log('original', originalData)
      modifiedData.value = originalData

      saveInstantData();
      console.log('modificado', modifiedData.value)
    }


    const comment = async () => {

      comentario = comentarios.value

      const originalData = await refGrid.value.$el.getSource()

      //console.log('se ejecuto comment', comentario)


      for (const cp of originalData) {
        cp['comentario'] = comentario
      }

      modifiedData.value = originalData
      comentarios.value = " "

      // console.log('comentado', modifiedData.value)
    }

    const clearRowSelection = () => {
      revoRowIndex.value = null
      revoColName.value = null
    }

    const clearRevoValues = () => {
      clearRowSelection()
      modifiedData.value = []
    }

    const clearBoxComment = async () => {
      comentario = comentarios.value = ""
      const originalData = await refGrid.value.$el.getSource()
      for (const rp of originalData) {
        rp['comentario'] = comentario
      }
      modifiedData.value = []
      //  console.log('se limpió', comentario)
    }


    const clearBoxAjustes = async () => {
      ajusteNS = ajustes.value = ""
      const originalData = await refGrid.value.$el.getSource()
      for (const rp of originalData) {
        rp['ns_new'] = ajusteNS
      }
      saveInstantData()
      // console.log('se limpió', ajusteNS)
    }

    const cargarData = () => {
      vm.$swal({
        title: `¿Está seguro de restaurar la data?`,
        text: 'Se restaurará la data al último punto guardado',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        backdrop: false,
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          return axios
            //.post(`http://127.0.0.1:3100/api/cargar_bd_presimulador`)
            .post(`${process.env.VUE_APP_BASE_URL}/restore_bd_presimulador`,
              { "id": cabecera })

            .then(response => {
              if (response.status != 200) {
                throw new Error(response.statusText)
              }
              return response
            })
            .catch(error => {
              vm.$swal.showValidationMessage(`Request failed:  ${error}`)
            })
        },
      }).then(async (result) => {
        if (result.value) {
          vm.$swal({
            icon: 'success',
            title: 'Data Reestablecida exitosamente!',
            customClass: {
              confirmButton: 'btn btn-success',
            }
          })
        }
      })
    }
    const beforeEditStart = (event) => {
      revoRowIndex.value = event.detail.rowIndex
      revoColName.value = event.detail.prop
    }

    const commitCell = async (event) => {
      const originalData = await refGrid.value.$el.getSource()
      const viewData = await refGrid.value.$el.getVisibleSource()
      let rowData = viewData[revoRowIndex.value]
      let oldValue = rowData[revoColName.value]
      let newValue = event.target.value
      if (oldValue != newValue) {
        rowData[revoColName.value] = newValue
        console.log('rowData', rowData);
        modifiedData.value.push(Object.assign({}, rowData))

        saveInstantData();
        // refGrid.value.$el.source = [...originalData]

      }
      clearRowSelection()
    }

    const saveData = async () => {
      if (modifiedData.value.length > 0) {
        vm.$swal({
          title: `¿Está seguro de que desea guardar los cambios?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            return axios
              //.post(`http://127.0.0.1:3100/api/cargar_bd_postsimulador`)
              .post(`${process.env.VUE_APP_BASE_URL}/cargar_bd_postsimulador`,
                { "id": cabecera })

              .then(response => {
                if (response.status != 200) {
                  throw new Error(response.statusText)
                }
                return response
              })
              .catch(error => {
                vm.$swal.showValidationMessage(`Request failed:  ${error}`)
              })
          },
        }).then(async (result) => {
          if (result.value) {
            vm.$swal({
              icon: 'success',
              title: 'Datos guardados!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              willClose: clearRevoValues
            })
          }
        })
      }
    }

    const saveInstantData = async () => {
      if (modifiedData.value.length > 0) {

        // console.log('data modificada: ', modifiedData.value);
        return axios
          //.post(`http://127.0.0.1:3100/api/update_simulador`)
          .post(`${process.env.VUE_APP_BASE_URL}/update_simulador`, {
            "data": modifiedData.value

          })
          .then(response => {
            if (response.status != 200) {
              throw new Error(response.statusText)

            }
            return response
          })
          .catch(error => {
            vm.$swal.showValidationMessage(`Request failed:  ${error}`)
          })

      }
      modifiedData.value = []
    }
    // subscription 
    columns.value = [
      {
        "name": "APPLICATION FORM",
        "prop": "application_form",
        "size": 160,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "TIPO",
        "prop": "clasificacion",
        "size": 85,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "AÑO",
        "prop": "year",
        "size": 60,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "MES",
        "prop": "month",
        "size": 60,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "NS ",
        "prop": "netsales",
        "size": 80,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },

      {
        "name": "NEW NS ",
        "prop": "ns_new",
        "size": 80,
        "autoSize": true,
        "sortable": true,
        "readonly": false,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "COMMENT",
        "prop": "comentario",
        "size": 120,
        "autoSize": true,
        "sortable": true,
        "readonly": false
      },
      {
        "name": "BRAND CATEGORY",
        "prop": "brand_category",
        "size": 170,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },  
      {
        "name": "BPU",
        "prop": "bpu",
        "size": 90,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },   
      {
        "name": "NS M1",
        "prop": "ns_m1",
        "size": 70,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "NS M2",
        "prop": "ns_m2",
        "size": 70,
        "autoSize": true,
        "sortable": true,
        "readonly": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },  
       
      {
        "name": "CANAL",
        "prop": "canal",
        "size": 80,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
    ]

    //const filter = computed(() => joinGlobalFilterValue());

    watch(filter, (newValue, oldValue) => {
      isLoading.value = true;

      // console.log('cambio', Date.now())
    }, { deep: true })

    watch(filtersData, (newValue, oldValue) => {
      joinGlobalFilterValue();

    },
      {
        deep: true
      }
    )

    watch(() => modifiedData.value, (newValue, oldValue) => {
      console.log('se actualiza', newValue)
    })

    vm.$apollo.addSmartSubscription('get_Simulador', {
      query: gql`
      subscription get_Simulador($customWhere: json = null) {
        function_get_bd_presimulador(args: {customWhere: $customWhere}, order_by: { clasificacion: asc, application_form: asc, month: desc, year: desc}) {                
              id
              clasificacion
              bpu
              brand_category
              application_form
              year
              month
              canal
              netsales
              ns_m1
              ns_m2
              productsales
              ns_new
              ps_new
              comentario
            }
          }
        `,
      variables() {
        return {
          customWhere: filter.value
        }
      },
      result({ data }) {
        isLoading.value = false

        source.value = data.function_get_bd_presimulador

        lastID = source.value[0]
        lastID ? cabecera.value = lastID['id'] : console.log(cabecera.value)
      },
    })

    // computed
    const isDisabledSaveData = computed(() => {

      //  saveData();

      return modifiedData.value.length <= 0
    })

    //onCreated
    clearRevoValues()

    return {
      source,
      columns,
      isLoading,
      refGrid,
      pluginRevo,

      clearBoxAjustes,
      clearBoxComment,
      submit,
      comment,
      beforeEditStart,
      commitCell,
      saveData,
      saveInstantData,
      cargarData,
      ajusteNS,
      comentario,
      clearRevoValues,
      isDisabledSaveData,
      filter,
      joinGlobalFilterValue,
      ajustes,
      comentarios,
      cabecera,
      lastID

    }
  },
}
</script>
  
<style lang="scss">
.grid-container-tabla-simulacion {
  width: 100%;
  font-size: 11px;
  font-family: "Montserrat", Helvetica, Arial, serif !important;
  height: 28.5rem;  

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
    
  }

  .header-rgRow {
    //background: #233285;
    background: #0032a0;
    color: white;
    text-align: left;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }

  .rgCell {
    padding: 0 7px;
  }


}

.btn-check{
  background-color: #58c450;
  //background-color: rgb(0, 191, 255);
  color: white;
}
.btn-check:hover{
  background-color: #39a831;
  color: white;
  box-shadow: 5px 7px 7px 1px rgba(70, 70, 70, 0.5);
}
.btn-clear{
  background-color: rgb(255, 58, 58);
  //background-color: #ff5e00;
  color: white;
}
.btn-clear:hover{
  background-color: rgb(248, 33, 33);
  color: white;
  box-shadow: 5px 7px 7px 1px rgba(70, 70, 70, 0.5);
}
.btn-save{
  background-color: #58c450;
  color: white;
}
.btn-save:hover{
  background-color: #39a831;
  color: white;
  box-shadow: 5px 7px 7px 1px rgba(70, 70, 70, 0.5);
}
.btn-rest{
  //background-color: #ffc300;
  background-color: #ff6f1c;
  //background-color: #FFBB00;
  color: white;
}
.btn-rest:hover{
  //background-color: #dfab00;
  background-color: #f85509;
  color: white;
  box-shadow: 5px 7px 7px 1px rgba(70, 70, 70, 0.5);
}
.btn-simulator{
  background-color: #0032a0;
  color: white;
}
.btn-simulator:hover{
  background-color: #002b88;
  color: white;
  box-shadow: 5px 7px 7px 1px rgba(70, 70, 70, 0.5);
}
@import '@core/scss/vue/libs/vue-sweetalert.scss';</style>
  