<template>
  <b-card>
    
    <div v-if="isLoading" class="my-3">
      <custom-loading></custom-loading>
    </div>
    <div v-else>
      <!--   <b-card-title class="text-center">
        <br> Sellin (NS/PS) vs SellOut (PS) vs Forecast (NS)
      </b-card-title>
      -->
      <!--   <span v-on:click="showTopFilter" style="cursor: pointer;">
        <span style="color: blue; font-size: 18px;">&#8680;</span> Mostrar
      </span>
      -->
      <!--    <b-row v-show="showTop">
        <b-col cols="5">
          <b-input-group prepend="Sell Out L3M" size="sm">
            <b-form-input size="sm" readonly v-model="sol3m">

            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="7">
          <b-input-group prepend="TS PS" size="sm">
            <b-form-input size="sm" readonly v-model="tsps">

            </b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      v-show="showTop"
-->
      
      <b-row class="ml-1" style="padding-top: 1.5rem;">
        <div class="justify-center align-center">
          <label id="graphic" style="font-size: medium; text-align: center; padding-bottom: 1.2rem;">Graphic S&OP</label> 
          <!-- <b-popover target="graphic" placement="right" triggers="hover">Gráfico NS por combinación "Type- Year"</b-popover> -->
        </div>

        <b-col cols="12">
          <b-dropdown id="dropdown-form" text="Year" ref="dropdown" class="mr-2" style="width: 120px;background-color: #0032a0;color: white;" variant="outline-light">
            <b-dropdown-form>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group @input="(val) => labelsFilter(val, 'year')" :options="yearOptions" input-id="year"
                  :aria-describedby="ariaDescribedby" v-model="filter.year" />
              </b-form-group>
            </b-dropdown-form>
          </b-dropdown>


          <b-dropdown id="dropdown-form" text="Type" ref="dropdown" style="width: 120px;background-color: #0032a0;color: white;" variant="outline-light">
            <b-dropdown-form>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group @input="(val) => labelsFilter(val, 'clasificacion')"
                  :options="clasificacionOptions" :aria-describedby="ariaDescribedby" input-id="clasificacion"
                  v-model="filter.clasificacion" />
              </b-form-group>
            </b-dropdown-form>
          </b-dropdown>

          <b-button v-on:click="clickFilter" style="cursor: pointer; background-color: rgba(255, 255, 255, 0);" variant="pink" class="ml-2" id="btnFiltrar">
            <span style="color: blue; font-size: 18px;">&#10227;</span> Filter
            <b-popover target="btnFiltrar" placement="topright" triggers="hover">Aplica filtros previamente seleccionados de "Year" y "Type" al gráfico.</b-popover> 
          </b-button>
          
          <b-button v-on:click="resetFilter" style="cursor: pointer; background-color: rgba(255, 255, 255, 0);" variant="pink"  class="ml-2" id="btnReset">
            <span style="color: blue; font-size: 18px;">&#10226;</span> Reset
            <b-popover target="btnReset" placement="topright" triggers="hover">Restablece "Filter".(Muestra todas las combinaciones "Year - Type" selecccionadas).</b-popover>
          </b-button>
          
        </b-col>
      </b-row>


      <b-card-body>
        <custom-bar-chart v-if="isLoaded" :height="455" :width="800" :chart-data="chartData" :options="options"
          ref="chart">
        </custom-bar-chart>
      </b-card-body>

    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BButton,
  BInputGroup, BFormInput, BInputGroupAppend, BFormGroup,
  BCardTitle, BRow, BCol, BFormCheckboxGroup, BDropdown, BDropdownForm,BPopover
} from 'bootstrap-vue'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import CustomBarChart from './CustomBarChart.vue'
import { ref, watch, toRefs } from 'vue'
import gql from 'graphql-tag'
import TradeStock from './TradeStock.vue'
import vSelect from 'vue-select'
import axios from 'axios'
import { includes } from 'postcss-rtl/lib/affected-props'
import NumberAbbreviate from 'number-abbreviate'
import { title } from 'process'


export default {
  components: {
    BCard,
    BCardBody,
    BButton,
    BCardTitle,
    BFormGroup,
    BFormCheckboxGroup,
    BDropdown,
    BDropdownForm,
    BInputGroup, BFormInput, BInputGroupAppend,
    CustomLoading,
    CustomBarChart,
    TradeStock,
    BRow,
    BCol,
    BPopover
  },
  props: {
    filtersData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      chart: null,
      isLoading: true,
      isLoaded: true,
      chartData: null,

      chartDataClone: null,
      checkboxLabelFilter: [],
      options: {
        plugins: {

        },
        legend: {
          labels: {
            fontSize: 11,
            fontFamily: "Montserrat, Helvetica, Arial, serif",

            fontWeight: "bold",
            filter: (legendItem, data) => {
              if (this.checkboxLabelFilter.length == 0) return true;

              if (this.checkboxLabelFilter.indexOf(legendItem.text.toUpperCase()) > -1) return true;
            },
          },
          position: "right"
          //display: false
        },
        tooltips: {
          enabled: true,
          displayColors: false,

          callbacks: {

            labelTextColor: function(context) {
                        return '#11ebf2';
                    },
/*
            labelColor: ((tooltipItems) => {
              console.log(tooltipItems)

              
                        return {
                            borderColor: 'rgb(0, 0, 255)',
                            backgroundColor: 'rgb(255, 0, 0)',
                            borderWidth: 2,
                            borderDash: [2, 2],
                            borderRadius: 2,
                        }
                    }),*/
            
            title: ((tooltipItems, data) => {
              let tt = tooltipItems[0]
              //let usFormat = tooltipItems.yLabel.toLocaleString('en-US');
              let lblds = data.datasets[tt.datasetIndex].label
             let meses = tt.xLabel
              //console.log(data.datasets[tt.datasetIndex].label)
              return [ meses + ' - ' + lblds]
            }),

            label:((tooltipItems, data) =>{           
              let porlm = 0;
              let porly = 0;
              let ns3m = 0;
              let ns6m = 0;
              let divisor = 0;
              let divisor2 = 0;
              let tt = tooltipItems
              let usFormat = tooltipItems.yLabel.toLocaleString('en-US');
              let lblds = data.datasets[tt.datasetIndex].label

              let nslm = data.datasets[tt.datasetIndex].data[tt.index - 1]
              let nsl2m = data.datasets[tt.datasetIndex].data[tt.index - 2]
              let nsl3m = data.datasets[tt.datasetIndex].data[tt.index - 3]
              let nsl4m = data.datasets[tt.datasetIndex].data[tt.index - 4]
              let nsl5m = data.datasets[tt.datasetIndex].data[tt.index - 5]
              let nsl6m = data.datasets[tt.datasetIndex].data[tt.index - 6]
              let nsly = 0 

              if (tt.datasetIndex - 1 > 0) {
                nsly = data.datasets[tt.datasetIndex-1].data[tt.index]
              }


              if (tt.index == 1) {
              
                // Si el mes es enero nslm obtiene los netsales del mes de diciembre del año pasado
                if (tt.datasetIndex - 1 > 0) {
                  nslm = data.datasets[tt.datasetIndex - 1].data[12]
                  nsl2m = data.datasets[tt.datasetIndex - 1].data[11]
                  nsl3m = data.datasets[tt.datasetIndex - 1].data[10]
                  nsl4m = data.datasets[tt.datasetIndex - 1].data[9]
                  nsl5m = data.datasets[tt.datasetIndex - 1].data[8]
                  nsl6m = data.datasets[tt.datasetIndex - 1].data[7]
                } else {
                  nslm = 0, nsl2m = 0,  nsl3m = 0, nsl4m = 0, nsl5m = 0,  nsl6m = 0
                }
              }
              if (nslm == null || nslm == undefined)  nslm = 0;
              if (nsl2m == null || nsl2m == undefined)  nsl2m = 0;
              if (nsl3m == null || nsl3m == undefined)  nsl3m = 0;
              if (nsl4m == null || nsl4m == undefined)  nsl4m = 0;
              if (nsl5m == null || nsl5m == undefined)  nsl5m = 0;
              if (nsl6m == null || nsl6m == undefined)  nsl6m = 0;
              if (nsly == null || nsly == undefined)  nsly = 0;

              nslm == 0 ? divisor = 1 : divisor = nslm
              nsly == 0 ? divisor2 = 1 : divisor2 = nsly

              ns3m =  (nslm + nsl2m + nsl3m)/3 
              ns6m =  (nslm + nsl2m + nsl3m + nsl4m + nsl5m + nsl6m)/6
              
           
              porlm = (parseFloat(tt.value) - nslm) * 100 / divisor
              porly = (parseFloat(tt.value) - nsly) * 100 / divisor2
               
              console.log(parseFloat(ns3m.toFixed(1)), parseFloat(ns6m.toFixed(1)))

              let ns3mFormat =   (parseFloat(ns3m.toFixed(1))).toLocaleString('en-US');
              let ns6mFormat =   (parseFloat(ns6m.toFixed(1))).toLocaleString('en-US');
              let porlmFormat  =   (parseFloat(porlm.toFixed(1))).toLocaleString('en-US');
              let porlyFormat  =   (parseFloat(porly.toFixed(1))).toLocaleString('en-US');

              console.log(ns3mFormat, ns6mFormat)
             // return '%LM: ' + porlm.toFixed(1) + ' % \n%LY: ' + porly.toFixed(1) + ' % \nL3M: ' + ns3mFormat + '  \nL6M: ' + ns6mFormat + ' ';

              return ['NS: '+ usFormat,
              'LM: ' + porlmFormat + ' %',
              'LY: ' + porlyFormat + ' %',
              'L3M: ' + ns3mFormat,
              'L6M: ' + ns6mFormat]
            }),
            
          }
           /*   custom: function(tooltipModel) {

                console.log(funcioncita())
               // console.log('entro aqui', tooltipModel)
                // Tooltip Element
                var tooltipEl = document.getElementById('chartjs-tooltip');
                window.onscroll = function() {
                    var y = window.scrollY;
                    return y;
                  };

              
                  // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML = "<table></table>"
                    document.body.appendChild(tooltipEl);
                }
               // console.log(tooltipEl)
                // Hide if no tooltip
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                 // console.log(window.onscroll())
                    tooltipEl.classList.add(tooltipModel.yAlign) ;
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                    return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                    var titleLines = tooltipModel.title || [];
                    var bodyLines = tooltipModel.body.map(getBody);

               

                    var innerHtml = '<thead>';

                    titleLines.forEach(function(title) {
                        innerHtml += '<tr><th>' + title + '</th></tr>';
                    });
                    innerHtml += '</thead><tbody>';

                    bodyLines.forEach(function(body, i) {
                        var colors = tooltipModel.labelColors[i];
                        var style = 'background:' + colors.backgroundColor;
                        style += '; border-color:' + colors.borderColor;
                        style += '; border-width: 2px';
                        var span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>';

                       
                        console. log (this)
                        let arreglo = body[0].split(":");
                        let newarreglo = arreglo.map(el=>el.trim());
                      

                        let usFormat = newarreglo[1].toLocaleString('en-US');
                        innerHtml += '<tr><td>' + span + ' '+ newarreglo[0] + ': '+  usFormat + '</td></tr>';
                        
                        innerHtml += '<tr><td>' + span + ' '+  'LM%: ' + '</td></tr>';
                        innerHtml += '<tr><td>' + span + ' '+  'LY%: ' + '</td></tr>';
                        innerHtml += '<tr><td>' + span + ' '+  'L3M%: ' + '</td></tr>';
                        innerHtml += '<tr><td>' + span + ' '+  'L6M%: ' + '</td></tr>';
                    });
                    innerHtml += '</tbody>';

                    var tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                // `this` will be the overall tooltip
                var position = this._chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = position.left + tooltipModel.caretX + 'px';
                tooltipEl.style.top = position.top + tooltipModel.caretY +  window.onscroll() + 'px';
                tooltipEl.style.fontFamily = "Montserrat, Helvetica, Arial, serif";
                tooltipEl.style.fontSize = 11
                tooltipEl.style.fontStyle = tooltipModel._fontStyle;
                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            }  */

        },

        responsive: true,
        interaction: {
          intersect: false,
        },
        maintainAspectRatio: false,
        responsiveAnimationDuration: 400,
        scales: {
          x: {
            stacked: true,
            min: 1,
          },
          xAxes: [{
            ticks: {
              fontSize: 12,
              fontFamily: "Montserrat, Helvetica, Arial, serif",
              fontWeight: "bold"
            }
          }],
          y: {
            stacked: true,
            min: 0,
            max: 20,
          },
          yAxes: [{
            ticks: {
              callback: function (value, index, ticks) {
                var usFormat = value.toLocaleString('en-US');
                return usFormat;
              },
              fontSize: 12,
              fontFamily: "Montserrat, Helvetica, Arial, serif",
              fontWeight: "bold"
            }
          }],

        }
      },
      showTop: false,
      filter: {
        year: this.obtenerFechas(),
        clasificacion: ['SELL IN', 'SELL OUT', 'FORECAST', 'STOCK', 'PROYECTADOS']
      },

    }
  },
  watch: {
    filtersData: function (newVal, oldVal) {
      this.isLoading = true
      this.filtersData = newVal
    }
  },


  methods: {
    obtenerFechas() {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const lastYear = currentDate.getFullYear() - 1
      const lastYear2 = currentDate.getFullYear() - 2
      const nextyear = currentDate.getFullYear() + 1

      return [lastYear2, lastYear, currentYear, nextyear]
    },

    showTopFilter() {
      this.showTop = !this.showTop
    },
    getClasificacionData(obj, clasificacion, year) {
      if (obj[clasificacion] == undefined || obj[clasificacion][year] == undefined) {

        return []
      }
      return obj[clasificacion][year]
    },
    formatChartData(data) {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const lastYear = currentDate.getFullYear() - 1
      const lastYear2 = currentDate.getFullYear() - 2
      const nextyear = currentDate.getFullYear() + 1
      const newObjData = {}

      // console.log('datos grafico', data)
      for (let obj of data) {
        let newObjClasificacion = {}
        let objYear = obj.year
        let objClasificacion = obj.clasificacion

        let mes1 = obj['mes_01']
        if (mes1 == 0) {
          mes1 = null
        }
        let mes2 = obj['mes_02']
        if (mes2 == 0) mes2 = null
        let mes3 = obj['mes_03']
        if (mes3 == 0) mes3 = null
        let mes4 = obj['mes_04']
        if (mes4 == 0) mes4 = null
        let mes5 = obj['mes_05']
        if (mes5 == 0) mes5 = null
        let mes6 = obj['mes_06']
        if (mes6 == 0) mes6 = null
        let mes7 = obj['mes_07']
        if (mes7 == 0) mes7 = null
        let mes8 = obj['mes_08']
        if (mes8 == 0) mes8 = null
        let mes9 = obj['mes_09']
        if (mes9 == 0) mes9 = null
        let mes10 = obj['mes_10']
        if (mes10 == 0) mes10 = null
        let mes11 = obj['mes_11']
        if (mes11 == 0) mes11 = null
        let mes12 = obj['mes_12']
        if (mes12 == 0) mes12 = null

        let objData = [obj[''], mes1, mes2, mes3, mes4, mes5, mes6, mes7, mes8, mes9, mes10, mes11, mes12, obj['']]
        newObjClasificacion[objYear] = objData
        newObjData[objClasificacion] = { ...newObjData[objClasificacion], ...newObjClasificacion }
      }

      this.chartData = {
        labels: [
          " ", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dic", " "
        ],
        datasets: [
          {
            $lblName: 'SELL IN ' + lastYear2,
            label: `Sell in ` + lastYear2,
            borderColor: "#B9DCB7",
            backgroundColor: "#B9DCB7",
            data: this.getClasificacionData(newObjData, "REALES", lastYear2),
            fill: false,
            hidden: false,
            tension: 0,
            datalabels: {
              display: false
            }
        
          },
          {
            $lblName: 'SELL IN ' + lastYear,
            label: `Sell in ` + lastYear,
            borderColor: "#74B96F",
            backgroundColor: "#74B96F",
            data: this.getClasificacionData(newObjData, "REALES", lastYear),
            fill: false,
            hidden: false,
            tension: 0,
            datalabels: {
              display: false
            }
          },

          {
            $lblName: 'SELL IN ' + currentYear,
            label: `Sell in ` + currentYear,
            borderColor: "#45A13F",
            backgroundColor: "#45A13F",
            data: this.getClasificacionData(newObjData, "REALES", currentYear),
            fill: false,
            hidden: false,
            tension: 0,
            datalabels: {
              display: false
            },

            tooltip: {
              display: false
            }
          },
          {
            $lblName: 'SELL OUT ' + lastYear2,
            label: `Sell out ` + lastYear2,
            borderColor: "#9FB2DB",
            backgroundColor: "#9FB2DB",
            data: this.getClasificacionData(newObjData, "SELL_OUT", lastYear2),
            fill: false,
            hidden: false,
            tension: 0,
            datalabels: {
              display: false
            }
          },


          {
            $lblName: 'SELL OUT ' + lastYear,
            label: `Sell out ` + lastYear,
            borderColor: "#4065B8",
            backgroundColor: "#4065B8",
            data: this.getClasificacionData(newObjData, "SELL_OUT", lastYear),
            fill: false,
            hidden: false,
            tension: 0,
            datalabels: {
              display: false
            }
          },


          {
            $lblName: 'SELL OUT ' + currentYear,
            label: `Sell out ` + currentYear,
            borderColor: "#0032A0",
            backgroundColor: "#0032A0",
            data: this.getClasificacionData(newObjData, "SELL_OUT", currentYear),
            fill: false,
            hidden: false,
            tension: 0,
            datalabels: {
              display: false
            }
          },


          {
            $lblName: 'FORECAST ' + lastYear2,
            label: `Forecast ` + lastYear2,
            borderColor: "#EAA49F",
            backgroundColor: "#EAA49F",
            fill: false,
            hidden: false,
            tension: 0,
            data: this.getClasificacionData(newObjData, "FORECAST", lastYear2),
            datalabels: {
              display: false
            }
          },


          {
            $lblName: 'FORECAST ' + lastYear,
            label: `Forecast ` + lastYear,
            borderColor: "#D54940",
            backgroundColor: "#D54940",
            fill: false,
            hidden: false,
            tension: 0,
            data: this.getClasificacionData(newObjData, "FORECAST", lastYear),
            datalabels: {
              display: false
            }
          },


          {
            $lblName: 'FORECAST ' + currentYear,
            label: `Forecast ` + currentYear,
            borderColor: "#C70C00",
            backgroundColor: "#C70C00",
            fill: false,
            hidden: false,
            tension: 0,
            data: this.getClasificacionData(newObjData, "FORECAST", currentYear),
            datalabels: {
              display: false
            }
          },
          {
            $lblName: 'STOCK ' + lastYear2,
            label: "Stock " + lastYear2,
            borderColor: "#FFE99F",
            backgroundColor: "#FFE99F",
            type: 'bar',
            fill: false,
            hidden: false,
            data: this.getClasificacionData(newObjData, "STOCK", lastYear2),
            tension: 0,
            // stack: 'Stack 2',
            datalabels: {
              display: false
            }
          },


          {
            $lblName: 'STOCK ' + lastYear,
            label: "Stock " + lastYear,
            borderColor: "#FFD240",
            backgroundColor: "#FFD240",
            type: 'bar',
            fill: false,
            hidden: false,
            data: this.getClasificacionData(newObjData, "STOCK", lastYear),
            tension: 0,
            // stack: 'Stack 2',
            datalabels: {
              display: false
            }
          },
          {
            $lblName: 'STOCK ' + currentYear,
            label: "Stock " + currentYear,
            borderColor: "#FFC300",
            backgroundColor: "#FFC300",
            type: 'bar',
            fill: false,
            hidden: false,
            data: this.getClasificacionData(newObjData, "STOCK", currentYear),
            tension: 0,
            // stack: 'Stack 2',
            datalabels: {
              display: false
            }
          },
          {
            $lblName: 'PROYECTADOS ' + currentYear,
            label: "Proyectados " + currentYear,
            borderColor: "#FCD1F4",
            backgroundColor: "#FCD1F4",
            fill: false,
            hidden: false,
            data: this.getClasificacionData(newObjData, "BLP", currentYear),
            tension: 0,
            datalabels: {
              display: false
            }
          },


          {
            $lblName: 'PROYECTADOS ' + nextyear,
            label: "Proyectados " + nextyear,
            borderColor: "#F575DE",
            backgroundColor: "#F575DE",
            fill: false,
            hidden: false,
            data: this.getClasificacionData(newObjData, "BLP", nextyear),
            tension: 0,
            datalabels: {
              display: false
            }
          },


        ]
      }

      //   console.log('asdasdasd', this.options)

      return this.chartData;
    },
    labelsFilter(val, key) {
      this.filter[key] = val || [];
      let items = [];


      if (this.filter['clasificacion'] && this.filter['clasificacion'].length > 0) {
        if (this.filter['year'].length > 0) {
          this.filter['clasificacion'].forEach(el => {
            this.filter['year'].forEach(eli => {
              items.push(`${el} ${eli}`.toUpperCase())
            })
          })
        } else {
          items = this.filter['clasificacion'].map(el => el.toUpperCase());
        }
      } else {
        items = this.filter['year'];
      }

      this.checkboxLabelFilter = items;
    },
    clickFilter(el) {
      let items = [];
      this.filter['clasificacion'].forEach(el => {
        this.filter['year'].forEach(eli => {
          items.push(`${el} ${eli}`.toUpperCase())
        })
      })
      this.checkboxLabelFilter = items;
      this.chartData.datasets.forEach((item, index) => {
        //    console.log('LB', this.checkboxLabelFilter, item.$lblName, this.checkboxLabelFilter.indexOf(item.$lblName));

        if (this.checkboxLabelFilter.indexOf(item.$lblName) == -1) {

          this.chartData.datasets[index].data = [];
        }
        else {
          this.chartData.datasets[index].data = this.chartDataClone.datasets[index].data
        }
      })

      this.isLoaded = false;
      setTimeout(() => this.isLoaded = true, 100);
    },
    resetFilter(el) {

      // console.log(this.chartDataClone);
      this.checkboxLabelFilter = [];
      this.filter['year'] = this.yearOptions;
      this.filter['clasificacion'] = this.clasificacionOptions;
      this.chartData = structuredClone(this.chartDataClone)


    }

  },
  setup() {

    const sol3m = ref('')
    const tsps = ref('')
    const isLoading = ref(true)
    const newFilterData = ref(null)
    const arreglito = ref([])
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const lastYear = currentDate.getFullYear() - 1
    const lastYear2 = currentDate.getFullYear() - 2
    const nextyear = currentDate.getFullYear() + 1

    const yearOptions = ref([lastYear2, lastYear, currentYear, nextyear])
    const clasificacionOptions = ref(['SELL IN', 'SELL OUT', 'FORECAST', 'STOCK', 'PROYECTADOS'])

    sol3m.value = "36.2"
    tsps.value = "59"
    // yearOptions.value = [lastYear2, lastYear, currentYear, nextyear]
    // clasificacionOptions.value = ['SELL IN', 'SELL OUT', 'FORECAST', 'STOCK', 'PROYECTADOS']

    return {
      sol3m,
      tsps,
      yearOptions,
      clasificacionOptions,
      isLoading,
      newFilterData,
      arreglito
      //seleccion
    }
  },

  apollo: {
    $subscribe: {
      BarChartData: {
        query: gql`
          subscription data_custom_chart($customWhere: json = null) {
            function_custom_chart(args: {customWhere: $customWhere}) {    
              clasificacion
              year
              mes_01
              mes_02
              mes_03
              mes_04
              mes_05
              mes_06
              mes_07
              mes_08
              mes_09
              mes_10
              mes_11
              mes_12
            }
          }
                
        `,
        variables() {
          /*  const globalFilter = this.filtersData;
            const localFilter = this.filter;
  
           for (const key in globalFilter) {
              if (!localFilter[key]) localFilter[key] = globalFilter[key] || [];
  
              if (globalFilter[key].length > 0) localFilter[key] = globalFilter[key] || [];
            }
  
            this.filter = localFilter;
            console.log('Se ejecuta variables', this.filter, this.filtersData)
  */
          return {

            customWhere: this.filtersData

          }
        },
        result({ data }) {
          this.chartDataClone = structuredClone(this.formatChartData(data.function_custom_chart))
          this.isLoading = false
        },
      }
    }
  }
}
</script>

<style lang="scss">
.dropdown-menu[x-placement=bottom-start] {
  margin-top: 0.2px !important;
}

.b-dropdown-form {
  padding: 0.0rem 0.8rem !important;

}

.custom-control-label {
  padding: 0.3rem 0rem;
}

.card-body {
  padding-bottom: 0rem;
  padding-right: 0rem;
  padding-left: 0.2rem;
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, .7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
}
</style>