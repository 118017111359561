<template>
  <b-modal
    id="fc-detail-modal"
    ref="fcDetailModal"
    centered
    no-close-on-backdrop
    title="Detalle"
    ok-title="Actualizar"
    @ok="updateData"
    @show="getData"
    size="lg"
    cancel-variant="outline-secondary"
  >
    
    <template #modal-ok>
      <div
        v-if="isLoadingButton"
      >
        <b-spinner
          small
        />
        Actualizando...
      </div>
      <div
        v-else
      >
        Actualizar
      </div>
    </template>

    <div
      v-show="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-show="!isLoading"
    >
 

      <v-grid
        ref="refGridFcModal"
        :source="source" 
        :columns="columns"
        :columnTypes="pluginRevo"
        theme="material" 
        resize="true"
        autoSizeColumn = true
        range="true"
        filter="true"
        class="grid-container-fc-modal"
        @beforeeditstart="beforeEditStart"
        @focusout="commitCell"
      ></v-grid>
    </div>
  </b-modal>
</template>

<script>
import { BButton, BModal, BCardText, BSpinner, BImg, BIconSuitDiamond } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, inject, getCurrentInstance } from 'vue'
import axios from '@axios'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import VGrid from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'

import { numericSort } from '@/libs/revo-grid/custom-sort'

export default {
  components: {
    BButton,
    BModal,
    BCardText,
    BSpinner,
    BImg,

    VGrid,
    CustomLoading,
 
  },
  directives: {
    Ripple,
  },
  setup() {
    const fcDetailModal = ref(null)
    const isLoadingButton = ref(false)
    const isLoading = ref(true)
    const source = ref([])
    const columns = ref([])
    const refGridFcModal = ref(null)
    const revoRowIndex = ref(null)
    const revoColName = ref(null)

    const pluginRevo = { 'numeric': new NumberColumnType('0,0') }

    const vm = getCurrentInstance().proxy

    // inject
    const selectedCell = inject("selectedCell")
    const filtersData = inject("filtersData")

    // default row
    const defaultRow = {
      id: 0,
      clasificacion: "",
      bpu: "",
      brand_category: "",
      application_form: "",
      promo_spgr: "",
      year: 0,
      month: 0,
      units: 0,
      netsales: 0,
      ajuste_netsales: 0,
      comentario: "",
    }

    columns.value = [
      {
        "name": "Canal",
        "prop": "canal",
        "size": 100,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "Bpu",
        "prop": "bpu",
        "size": 100,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "Brand Category",
        "prop": "brand_category",
        "size": 160,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "Application Form",
        "prop": "application_form",
        "size": 170,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "Descripción",
        "prop": "promo_spgr",
        "size": 150,
        "autoSize": true,
        "sortable": true,
        "readonly": true
      },
      {
        "name": "Netsales",
        "prop": "netsales",
        "size": 110,
        "autoSize": true,
        "sortable": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
        "readonly": true
      },
      {
        "name": "Ajuste",
        "prop": "ajuste_netsales",
        "size": 100,
        "autoSize": true,
        "sortable": true,
        "cellCompare": numericSort,
        "columnType": 'numeric',
      },
      {
        "name": "Comentario",
        "prop": "comentario",
        "size": 140,
        "autoSize": true,
        "sortable": true,
      },
    ]

    // methods
    const getData = async () => {
      try {
        isLoading.value = true
        
        const result = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/info_db_main`,{
          data : {
            clasificacion: selectedCell.value.model.clasificacion,
            year: selectedCell.value.model.year,
            month: getMonthFromString(selectedCell.value.prop),
            bpu: getFilteredData(filtersData.value?.bpu),
            brand_category: getFilteredData(filtersData.value?.brand_category),
            application_form: getFilteredData(filtersData.value?.application_form),
          }
        })
        source.value = result.data.result
      } catch(e) {
        console.log(e)
      } finally {
        isLoading.value = false
      }
    }



    const clearRowSelection = () => {
      revoRowIndex.value = null
      revoColName.value = null
    }

    const beforeEditStart = (event) => {
      revoRowIndex.value = event.detail.rowIndex
      revoColName.value = event.detail.prop
    }

    const commitCell = async (event) => {
      const originalData = await refGridFcModal.value.$el.getSource()
      const viewData = await refGridFcModal.value.$el.getVisibleSource()
      let oldValue = viewData[revoRowIndex.value][revoColName.value]
      let newValue = event.target.value
      if(revoColName.value == 'ajuste_netsales' ) {
        newValue = parseInt(newValue)
      }
      
      if (oldValue != newValue) {
        viewData[revoRowIndex.value][revoColName.value] = newValue
        refGridFcModal.value.$el.source = [...originalData]
      }

      clearRowSelection()
    }

    const getMonthFromString = (data) => {
      return parseInt(data.split('_')[1])
    }

    const getFilteredData = (data) => {
      if (data == undefined || data.length == 0) return ''
      return data[0]
    }

    const updateData = async (bvModalEvt) => {
      bvModalEvt.preventDefault()
      const btnModal = document.getElementById('fc-detail-modal___BV_modal_footer_').children[1]

      try {
        isLoadingButton.value = true
        
        btnModal.setAttribute('disabled', true)

        await axios
        .post(`${process.env.VUE_APP_BASE_URL}/update_db_main`,{
          data: source.value
        })

        vm.$swal({
          title: 'Datos Actualizados!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          willClose: ()=> {
            getData()
          }
        })
      } catch(e) {
        console.log(e)
        vm.$swal({
          title: 'Error al actualizar!',
          text: `Intente de nuevo`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } finally {
        isLoadingButton.value = false
        btnModal.removeAttribute('disabled')
      }
    }

    return {
      source,
      columns,
      fcDetailModal,
      refGridFcModal,
      isLoadingButton,
      pluginRevo,
      isLoading,

      beforeEditStart,
      commitCell,
      updateData,


      getData,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

#fc-detail-modal {
  .modal-lg {
    max-width: 1150px;
  }
}

.grid-container-fc-modal {
  width: 100%;
  height: 25vh;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }
}
</style>