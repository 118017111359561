import { render, staticRenderFns } from "./TablaForecastComp.vue?vue&type=template&id=56e8fae0&"
import script from "./TablaForecastComp.vue?vue&type=script&lang=js&"
export * from "./TablaForecastComp.vue?vue&type=script&lang=js&"
import style0 from "./TablaForecastComp.vue?vue&type=style&index=0&id=56e8fae0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports