<template>
  <b-card no-body style="width: fit-content;">

    <div v-if="isLoading" class="my-3">
      <custom-loading></custom-loading>
    </div>
    <div v-else>
    <!--  <b-card-title class="text-center">
        <br> Sellin Quarter vs LY vs Forecast
      </b-card-title>-->
      <b-card-body >
        <v-grid ref="refGridSopTpen" :source="source" :columns="columns" :columnTypes="pluginRevo" theme="material"
          resize="true" autoSizeColumn=true class="grid-container-sop-tpen" @beforeeditstart="beforeEditStart"
          style="max-height: 28vh; max-width: fit-content;"
          @focusout="commitCell"></v-grid>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BCardBody, BCardTitle,
  BRow,
} from 'bootstrap-vue'
import { ref, getCurrentInstance } from 'vue'
import VGrid from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,
    BCardTitle,
    CustomLoading,
    VGrid,
  },
  setup(props) {
    const source = ref([])
    const isLoading = ref(true)
    const refGridSopTpen = ref(null)
    const columns = ref([])
    const revoRowIndex = ref(null)
    const revoColName = ref(null)
    const pluginRevo = {
      'numeric': new NumberColumnType('0,0'),
      'percentage': new NumberColumnType('0,0[.]00 %'),
      'numeric_negative': new NumberColumnType('(0,0)'),
    };

    const cellStyle = {
      sop_vs_py: true,
      fc_vs_py: true,
    }

    const vm = getCurrentInstance().proxy

    //methods
    const clearRowSelection = () => {
      revoRowIndex.value = null
      revoColName.value = null
    }

    const beforeEditStart = (event) => {
      revoRowIndex.value = event.detail.rowIndex
      revoColName.value = event.detail.prop
    }

    const commitCell = async (event) => {
      const originalData = await refGridSopTpen.value.$el.getSource()
      const viewData = await refGridSopTpen.value.$el.getVisibleSource()
      let oldValue = viewData[revoRowIndex.value][revoColName.value]
      let newValue = event.target.value
      // if(revoColName.value == 'ajuste_netsales' ) {
      //   newValue = parseInt(newValue)
      // }

      if (oldValue != newValue) {
        viewData[revoRowIndex.value][revoColName.value] = newValue
        refGridSopTpen.value.$el.source = [...originalData]
      }
      clearRowSelection()
    }

    const getStyleGrid = (prop, model, column) => {


      let propvsLy = model.sop_vs_py
      let propvsFC = model.fc_vs_py

      let customStyle = {
        "text-align": "right"
      }

      if (column.prop == "sop_vs_py"
        || column.prop == "fc_vs_py") {
        delete customStyle["text-align"]
      }



      if (cellStyle[prop]) {
        customStyle["color"] = "green";

        if (model[prop] < 0) {
          customStyle["color"] = "red";
        }
      }

      return customStyle;
    }

    columns.value = [
      {
        "name": "YEAR",
        "prop": "year",
        "size": 65,
        "autoSize": true,
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "Q",
        "prop": "quarter",
        "size": 50,
        
        "autoSize": true,
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "NS",
        "prop": "netsales",
        "size": 85,
        "autoSize": true,
        "columnType": 'numeric',
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },

      {
        "name": "%vs LY",
        "prop": "fc_vs_py",
        "size": 80,
        "autoSize": true,
        "columnType": 'percentage',
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      },
      {
        "name": "%vs FC",
        "prop": "sop_vs_py",
        "size": 80,
        "autoSize": true,
        "columnType": 'percentage',
        "readonly": true,
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
      }
    ]

    // subscription
    vm.$apollo.addSmartSubscription('getdataTpen', {
      query: gql`
      subscription getdataTpen($customWhere: json = null) {
        function_get_quarter_lyvf(args: {customWhere: $customWhere}, order_by: {year: desc, quarter: desc} )  {    
            
            year
            quarter
            netsales
            sop_vs_py
            fc_vs_py
   
          }
        }
      `,
      result({ data }) {
        isLoading.value = false
        source.value = data.function_get_quarter_lyvf
        console.log('la data es:' ,source.value)

      },
    })

    return {
      source,
      columns,
      isLoading,
      refGridSopTpen,
      pluginRevo,

      beforeEditStart,
      commitCell,
    }
  },
}
</script>

<style lang="scss">
.grid-container-sop-tpen {

  width: 100%;
  height: 480px;
  font-size: 12px;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
