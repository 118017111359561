<template>
  <b-card no-body style=" max-height: fit-content; padding-top: -5rem;">

    <div v-if="isLoading" class="my-3">
      <custom-loading></custom-loading>
    </div>
    <div v-else>
      <!-- <div class="line"></div> -->
      <b-card-body style="padding-top: 0rem;">
        <div v-b-tooltip.hover="'Filtro exclusivo'">
          <label id="tittle" style="font-size: medium;">Simulator Comparative S&OP</label>
        </div>
       <b-popover target="tittle" placement="right" triggers="hover">Cuadro comparativo REALES + PROY por Año-Mes (netsales en K, % vs Last Year Y % vs Forecast)</b-popover> 

        <div style="text-align: end;">
          <span :style="showTotals ? { 'visibility': '', 'text-align': 'end' } : { 'visibility': 'hidden' }" class="mr-1">
            <strong>{{ 'Sum: ' + sumAbv }}</strong></span>
          <span :style="showTotals ? { 'visibility': '', 'text-align': 'end' } : { 'visibility': 'hidden' }"><strong>{{
            'Avg: ' + avgAbv }}</strong></span>
          <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; *Amount in thousands</strong>
        </div>
        <v-grid ref="refGridFc" :source="source" :columns="columns" :grouping="groupConfig" :columnTypes="pluginRevo" 
          theme="material" resize="true" autoSizeColumn=true range=true class="grid-container-sellinproy" 
          style="max-height: 25vh;" @beforeeditstart="beforeEditStart" @beforerangeedit="beforeRangeEdit"
          @beforeaange="beforeRange" @mouseup="onMouseUp"></v-grid>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BCardBody,
  BRow,BDropdownDivider,BPopover
} from 'bootstrap-vue'
import { ref, getCurrentInstance, provide, watch, toRefs, computed } from 'vue'
import VGrid from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'


import { async } from 'q'
import { AlignCenterIcon } from 'vue-feather-icons'
import index from 'vue-prism-component'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,
    CustomLoading,
    VGrid,
    BDropdownDivider,
    BPopover
},
  props: {
    filtersData: {
      type: Object,
      default: null
    },
    dimension: true
  },
  // ??
  setup(props) {
    // props
    const { filtersData } = toRefs(props)
    const abbreviate = require('number-abbreviate')
    const source = ref([])
    const isLoading = ref(true)
    const refGridFc = ref(null)
    const groupConfig = ref({})
    const columns = ref([])
    const selectedCell = ref(null)
    const pluginRevo = {
      'numeric': new NumberColumnType('0,0[.]0'),
      'percentage': new NumberColumnType('0,0[.]00 %'),
      'numeric_negative': new NumberColumnType('(0,0)'),
    };
    const gridSum = ref(0)
    const gridAvg = ref(0)
    const sumAbv = ref(null)
    const avgAbv = ref(null)
    let arregloColumnas = ref([])

    const rangeSelected = ref({})

    const vm = getCurrentInstance().proxy


    const cellStyle = {
      sop_vs_py1: true,
      fc_vs_py1: true,
      sop_vs_py2: true,
      fc_vs_py2: true,
      sop_vs_py3: true,
      fc_vs_py3: true,
      sop_vs_py4: true,
      fc_vs_py4: true,
    }

    const cellStyle2 = {
      total_ly: true,
      total_fc: true
    }

    // provide  -  no se usa
    provide('selectedCell', selectedCell)
    provide('filtersData', filtersData)

    // computed
    const showTotals = computed(() => {
      return gridSum.value > 0 && gridSum.value > 0

    })

    //methods
    const beforeEditStart = (event) => {
      event.preventDefault()
      selectedCell.value = event.detail
      /*
            if (selectedCell.value.model.year.includes("-M") || selectedCell.value.model.year.includes("-m")) return

            if (selectedCell.value.model.clasificacion.toLowerCase().includes('reales')) return

            vm.$bvModal.show('fc-detail-modal')
      */

    }

    const beforeRange = (event) => {
      rangeSelected.value = event.detail.newRange
    }

    const beforeRangeEdit = async (event) => {
      event.preventDefault()

      const viewData = await refGridFc.value.$el.getVisibleSource()

      let totalSum = 0
      let totalCount = 0


      //console.log ('seleccion',rangeSelected.value)

      // verificar si es columna o no
      let isColumnSum = rangeSelected.value.x === rangeSelected.value.x1 && rangeSelected.value.y !== rangeSelected.value.y1
      let isRowSum = rangeSelected.value.y === rangeSelected.value.y1 && rangeSelected.value.x !== rangeSelected.value.x1

      //Para x=0 , y=0  hasta x1=0 , y1=1
      if (isColumnSum) {
        for (let i = rangeSelected.value.y; i <= rangeSelected.value.y1; i++) { // for (let i= 0 ; i<=1; i++)
          const rowObject = viewData[i]                                         //x:0, y:0  [0,0,0,0,89,10000....]
          const rowData = Object.values(rowObject)                              //x:0, y:1  [10890,10890,10890....]

          //  console.log('parametros ', rowData, rowData.length)
          if (rowData.length > 25) { // check si es la fila con datos , lenght = 15
            totalSum += rowData[rangeSelected.value.x + 2]
            totalCount++
          } 

          //  console.log('funcion suma en if trae ', totalSum)
        }
      }
      if (isRowSum) {

        for (let i = rangeSelected.value.x + 1; i <= rangeSelected.value.x1 + 1; i++) { // for (let i= 0 ; i<=0; i++)
          const rowObject = viewData[rangeSelected.value.y]
          const rowData = Object.values(rowObject)

          //console.log('parametros ',rowObject, rowData, rowData.length)
          if (rowData.length > 25) { // check si es la fila con datos|
            totalSum += rowData[i + 1]
            totalCount++
          }
          // console.log("valor de i", i)
          //console.log('funcion suma en else trae ',totalSum)

        }
      }

      gridSum.value = (totalSum).toFixed(2)
      sumAbv.value = abbreviate(gridSum.value)
      // console.log('la suma abreviada es ',sumAbv)
      gridAvg.value = (totalSum / totalCount).toFixed(2)
      avgAbv.value = abbreviate(gridAvg.value)


    }

    const onMouseUp = async (event) => {
      event.preventDefault()
      rangeSelected.value = {}
      gridSum.value = 0
      sumAbv.value = 0
      gridAvg.value = 0
      avgAbv.value = 0
    }

// COLOR TRANSPARENTE EN LAS COLUMNAS DE LAS TABLAS 
    const getStyleGrid = (prop, model, column) => {
      // if (!model.clasificacion.toLowerCase().includes('reales')) return
      // let dateObj = new Date();
      // let monthNumber = dateObj.getUTCMonth() + 1; //months from 1-12
      // let year = dateObj.getUTCFullYear();


      // let propMonth = parseInt(prop.split('_')[1])

      // let propYear = model.year

      let customStyle = {
        "text-align": "right"
      }

      if (prop == "nsq1" || prop == "nsq2" || prop == "nsq3" || prop == "nsq4") {
        return {
          "text-align": "center",
          fontWeight: "bold",
          //color fondo
          background: "#F6F6F6"
        }

      }

      if (prop == "total") {
        return {
          "text-align": "center",
          fontWeight: "bold",
          //background: "#ebf1fa"
          background: "#EDF2FF"
        }
      }
            
// CAMBIO DE COLOR DE LOS NÚMEROS DE %LY Y %FC
      if (cellStyle[prop]) {
        //console.log(cellStyle.Total_FC, cellStyle.Total_LY);
        customStyle["color"] = "black";
        customStyle["text-align"] = "center";
        customStyle["background"] = "#F6F6F6";
        customStyle["fontWeight"] = "bold";
        customStyle["display"] ="block";
        if (model[prop] > 0) {
          customStyle["color"] = "green";
          customStyle["text-align"] = "center";
          customStyle["background"] = "#F6F6F6";
          customStyle["fontWeight"] = "bold";
          customStyle["display"] ="block";
        }
        if (model[prop] < 0) {
          customStyle["color"] = "red";
          customStyle["text-align"] = "center";
          customStyle["background"] = "#F6F6F6";
          customStyle["fontWeight"] = "bold";
          customStyle["display"] ="block";
        }
      }

      if (cellStyle2[prop]){
        customStyle["color"] = "black";
        customStyle["text-align"] = "center";
        customStyle["background"] = "#EDF2FF";
        customStyle["fontWeight"] = "bold";
        customStyle["display"] ="block";
        if (model[prop] > 0) {
          customStyle["color"] = "green";
          customStyle["text-align"] = "center";
          customStyle["background"] = "#EDF2FF";
          customStyle["fontWeight"] = "bold";
          customStyle["display"] ="block";
        }
        if (model[prop] < 0) {
          customStyle["color"] = "red";
          customStyle["text-align"] = "center";
          customStyle["background"] = "#EDF2FF";
          customStyle["fontWeight"] = "bold";
          customStyle["display"] ="block";
        }
      }

      return customStyle;
    }

    

 // no usa
    const ocultarColumnas = async ()=>{

      const viewData = await refGridFc.value.$el.getVisibleSource()

      console.log('refGrid',viewData)
    /*   let consulta; 
      consulta = document.querySelectorAll('.q1');

      for(const el in consulta){
        console.log(consulta[el])      
        consulta[el].style.display='none',
        consulta[el].style.width='none'
      }
*/

// no usa 
    }
    const getFormatNumber = (prop, model) => {


      const dataparse = [];

      for (const l in model) {
        obj[l] = {};

        for (const a in model[l]) {
          //console.log('dentro del for',dataoriginal[l][a],a)

          obj[l][a] = a === 'year' ? model[l][a] : abbreviate(model[l][a])
        }

        dataparse.push(obj)

      }

    }



    // config table
    /*groupConfig.value = {
      props: ['clasificacion'],
      expandedAll: true,
    }*/

    
    
    arregloColumnas = [
      {
        "name": "*",
        "prop": "year",
        "size": 39,
        "autoSize": true,
        "readonly": true,
        "pin": "colPinStart"

      },
      {
        "name": "Ene",
        "prop": "mes_01",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
      },
      {
        "name": "Feb",
        "prop": "mes_02",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
            // columnType: getFormatNumber(prop, model)
          }
        },
      },
      {
        "name": "Mar",
        "prop": "mes_03",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
      },
      {
        "name": "Q1",
        "prop": "nsq1",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              background:'#2F56AA'
            },
            class: {
              'size': 10
            }
          };
        },
/*
        columnTemplate: (createElement, column) => {
          return createElement('button', {
            style: {
              color: 'white',
              background: '#0032a0'
            },
            onclick: event=>{
             const cols = arregloColumnas
             cols.forEach( (col,index) => {

               // console.log(col)
                if( ['sop_vs_py1', 'fc_vs_py1'].includes(col.prop)){
                    cols[index].size = 10
                    cols[index].resize = true
                    //isLoading.value = true
                }
              })
              ocultarColumnas();
              //console.log(columns.value) 
              //columns.value = arregloColumnas
             // isLoading.value = false
             // ocultarColumnas();
            }
              
          }, column.name);
        },
*/
      },
      {
        "name": "%LY",
        "prop": "sop_vs_py1",
        "size": 56,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
            class: 'q1'

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background:'#2F56AA'
            },
            class: 'q1'
            
          };
        },
      },
      {
        "name": "%FC",
        "prop": "fc_vs_py1",
        "size": 56,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
            class: 'q1'
          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background: '#2F56AA'
            },
            class: 'q1'
            
          };
        },
      }
      ,
      {
        "name": "Abr",
        "prop": "mes_04",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
      },
      {
        "name": "May",
        "prop": "mes_05",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
      },
      {
        "name": "Jun",
        "prop": "mes_06",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
      },
      {
        "name": "Q2",
        "prop": "nsq2",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              background:'#2F56AA'
            },
            class: {
              'size': 10
            }
          };
        },
      },
      
      {
        "name": "%LY",
        "prop": "sop_vs_py2",
        "size": 56,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background: '#2F56AA'
            },
            class: 'q1'
            
          };
        },
      },
      {
        "name": "%FC",
        "prop": "fc_vs_py2",
        "size": 56,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background: '#2F56AA'
            },
            class: 'q1'
            
          };
        },
      },

      {
        "name": "Jul",
        "prop": "mes_07",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
      },
      {
        "name": "Ago",
        "prop": "mes_08",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
      },
      {
        "name": "Sep",
        "prop": "mes_09",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
      },
      {
        "name": "Q3",
        "prop": "nsq3",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              background:'#2F56AA'
            },
            class: {
              'size': 10
            }
          };
        },
      },
      {
        "name": "%LY",
        "prop": "sop_vs_py3",
        "size": 56,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background: '#2F56AA'
            },
            class: 'q1'
            
          };
        },
      },
      {
        "name": "%FC",
        "prop": "fc_vs_py3",
        "size": 56,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background: '#2F56AA'
            },
            class: 'q1'
            
          };
        },
      }
      ,

      {
        "name": "Oct",
        "prop": "mes_10",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
      },
      {
        "name": "Nov",
        "prop": "mes_11",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
      },
      {
        "name": "Dic",
        "prop": "mes_12",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
      },
      {
        "name": "Q4",
        "prop": "nsq4",
        "size": 58,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              background:'#2F56AA'
            },
            class: {
              'size': 10
            }
          };
        },
      },
      {
        "name": "%LY",
        "prop": "sop_vs_py4",
        "size": 56,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background: '#2F56AA'
            },
            class: 'q1'

          };
        },
      },  
      {
        "name": "%FC",
        "prop": "fc_vs_py4",
        "size": 56,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)

          }
        },
        columnProperties: ({ prop, model, data, column }) => {
          return {
            style: {
              color: 'white',
              display: 'block',
              background: '#2F56AA'
            },
            class: 'q1'
            
          };
        },
      },


      {
        "name": "Total",
        "prop": "total",
        "size": 95,
        "autoSize": true,
        "columnType": 'numeric',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column),
          }
        },
        columnProperties:({prop,model,data,column}) => {
          return {
            style: {
              background: "#264996",
            }
          }
        }
      },
      
      {
        "name": "Total %LY",
        "prop": "total_ly",
        "size": 95,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
        columnProperties:({prop,model,data,column}) => {
          return {
            style: {
              background: "#264996",
            }
          }
        }
      },
      {
        "name": "Total %FC",
        "prop": "total_fc",
        "size": 95,
        "autoSize": true,
        "columnType": 'percentage',
        cellProperties: ({ prop, model, data, column }) => {
          return {
            style: getStyleGrid(prop, model, column)
          }
        },
        columnProperties:({prop,model,data,column}) => {
          return {
            style: {
              background: "#264996",
            }
          }
        }
      },
      
    ]

    columns.value = arregloColumnas; 

    watch(filtersData, (newValue, oldValue) => {
      isLoading.value = true
      filtersData.value = newValue
    })

    // subscription
    vm.$apollo.addSmartSubscription('getSellinProy', {
      query: gql`
        subscription getSellinProy($customWhere: json = null) {
          function_get_db_sellin_proy_fc(args: {customWhere: $customWhere}, order_by: {year: desc}) {
        
            clasificacion
            year
            mes_01
            mes_02
            mes_03
            nsq1
            sop_vs_py1
            fc_vs_py1
            mes_04
            mes_05
            mes_06
            nsq2
            sop_vs_py2
            fc_vs_py2
            mes_07
            mes_08
            mes_09
            nsq3
            sop_vs_py3
            fc_vs_py3
            mes_10
            mes_11
            mes_12
            nsq4
            sop_vs_py4
            fc_vs_py4
            total: Total
            total_ly: Total_LY
            total_fc: Total_FC
          }
        }
      `,
      variables() {
        return {
          customWhere: filtersData.value
        }
      },
      result({ data }) {
        let dataoriginal = [];
        let dataparse = []
        isLoading.value = false
        const obj = {}
        dataoriginal = data.function_get_db_sellin_proy_fc

        // console.log('la data original', dataoriginal)

        /*  for (const l in dataoriginal) {
            obj[l] = {};
  
            for (const a in dataoriginal[l]) {
              //console.log('dentro del for',dataoriginal[l][a],a)
  
              obj[l][a] = a === 'year' ? dataoriginal[l][a] : abbreviate(dataoriginal[l][a])
            }
  
            dataparse.push(obj)
  
          }
          console.log('dataparse', dataparse)
  */
        source.value = dataoriginal
      },
    })

    /*  dataparse.push(abbreviate(dataoriginal[l].mes_01,2))
  dataparse.push(abbreviate(dataoriginal[l].mes_02,2))
  dataparse.push(abbreviate(dataoriginal[l].mes_03,2))
  dataparse.push(abbreviate(dataoriginal[l].mes_04,2))
  dataparse.push(abbreviate(dataoriginal[l].mes_05,2))
  dataparse.push(abbreviate(dataoriginal[l].mes_06,2))
  dataparse.push(abbreviate(dataoriginal[l].mes_07,2))
  dataparse.push(abbreviate(dataoriginal[l].mes_08,2))
  dataparse.push(abbreviate(dataoriginal[l].mes_09,2))
  dataparse.push(abbreviate(dataoriginal[l].mes_10,2))
  dataparse.push(abbreviate(dataoriginal[l].mes_11,2))
  dataparse.push(abbreviate(dataoriginal[l].mes_12,2))
  dataparse.push(abbreviate(dataoriginal[l].total,2))
*/

    return {
      groupConfig,
      source,
      columns,
      isLoading,
      refGridFc,
      pluginRevo,
      gridSum,
      gridAvg,
      sumAbv,
      avgAbv,
      showTotals,

      beforeRange,
      beforeEditStart,
      beforeRangeEdit,
      onMouseUp,

    }
  },
}
</script>

<style lang="scss">
.grid-container-sellinproy {

  width: 100%;
  height: 400px;
  font-size: 11px;
  font-family: "Montserrat", Helvetica, Arial, serif !important;



  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .actual-rgRow {
    background: #0032a0;
    color: white;
  }

  .group-rgRow {
    .rgHeaderCell {
      color: white;
      background: #6783c0;
      .resizable {
        background: white;
      }
    }

  }
  .rgCell{
    padding: 0 2.5px;
  }
}

.line{
  border-top: 1px solid rgb(194, 194, 194);
  height: 0.2px;
  padding: 0;
  margin: 20px auto 0 auto;
}
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
